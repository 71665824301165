import React from 'react';
import PropTypes from 'prop-types';
import ChooseOneQuestion from './ChooseOneQuestion';
import './choose-one-style.css';

const ChooseOne = ({ prompt, question, answers, isGateway, handleSubmit, submittedAnswer, buttonHelper, ...props }) => {

	return (
		<div className="choose-one--container">
			{prompt &&
				<div className="choose-one--prompt">
					<h3>{prompt}</h3>
				</div>
			}

			<ChooseOneQuestion
				questionNumber={question.questionNumber}
				style={question.style}
				image={question.image}
				imageAlt={question.imageAlt}
				supplemental={question.supplemental}
				text={question.text}
				isGateway={isGateway}
				handleSubmit={handleSubmit}
				submittedAnswer={submittedAnswer}
				answers={answers}
				buttonHelper={buttonHelper} />
		</div>
	);
};

ChooseOne.propTypes = {
	prompt: PropTypes.string,
	question: PropTypes.shape({
		style: PropTypes.string,
		image: PropTypes.string,
		text: PropTypes.string
	}),
	answers: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.string,
		text: PropTypes.string
	}))
}

export default ChooseOne;