import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import OrderingItem from './OrderingItem';

const OrderingList = SortableContainer(({ items, requirements, flags, isWarning, ...props }) => {

	return (
		<ol className="ordering--list">
			{
				items.map((item, index) =>
					<OrderingItem key={index}
						id={item.id}
						index={index}
						isRequirement={isWarning && requirements.includes(item.id)}
						isFlag={isWarning && flags.includes(item.id)}
						text={item.text} />
				)
			}
		</ol>
	);
});

export default OrderingList;
