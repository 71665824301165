import React from 'react';
import PropTypes from 'prop-types';
import { ShieldLock } from 'react-bootstrap-icons';
import TopicCard from './TopicCard';

const UnitCard = ({ icon, title, number, topics, locked, ...props }) => {

	const buildAriaLabel = () => {
		var label = `Unit ${number}: ${title}`;
		if (locked) {
			label += "; Unit is locked";
		}

		return label;
	}

	const unitCardId = number === 1 ? "main-content" : `profile--unit-card--${number}`;

	return (
		<div id={unitCardId} role="region" aria-labelledby={`unit-${number}-header`}
			className={["profile--unit-card", "dark", locked ? "profile--unit-card--locked" : "profile--unit-card--active"].filter(e => !!e).join(' ')}>

			<div className="profile--unit-card--title" aria-hidden="true">{title}</div>
			<h3 id={`unit-${number}-header`} className="sr-only">{buildAriaLabel()}</h3>

			{/* TODO: storybook complained about the lack of role when using aria-label on div, but not sure if this is the right solution */}
			{locked &&
				<div className="profile--unit-card--icon--container" aria-hidden="true">
					<ShieldLock className="profile--unit-card--locked--glyph" />
					<img className="profile--unit-card--icon"
						src={process.env.REACT_APP_URL + '/assets/images/units' + icon} alt="" />
				</div>
			}

			{!locked &&
			<div className="profile--unit-card--topics--container">
				{topics && topics.map((topic, index) =>
					<TopicCard key={'topic-card-' + index}
						unitNumber={number}
						title={topic.title}
						locked={topic.locked}
						progress={topic.progress}
						iniitalActivityId={topic.activityId} />
				)}
			</div>
			}
		</div>
	);
};

UnitCard.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	number: PropTypes.number,
	topics: PropTypes.array,
	locked: PropTypes.bool
}

UnitCard.defaultProps = {
	icon: "/identity-icon.svg",
	title: "Example Unit",
	number: 1,
	topics: [],
	locked: true
}

export default UnitCard;