import React from 'react';
import PropTypes from 'prop-types';
import Sorting from './Sorting/Sorting';

const SortingView = ({ view, ...props }) => {

	return (
		<Sorting prompt={view.prompt} questions={view.questions} answers={view.answers} />
	);
};

SortingView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['spinningWheel']),
		...Sorting.propTypes
	}).isRequired
}

export default SortingView;