import React, { useRef } from 'react';
import { useCountUp } from 'react-countup';
import PropTypes from 'prop-types';

const UserPoints = ( { totalPoints, awardedPoints, duration, ...props } ) => {
	const countUpRef = useRef(null);

	const getStartPoints = () => {
		if (awardedPoints && awardedPoints > 0) {
			return Math.max(0, totalPoints - awardedPoints);
		}

		return Math.max(0, totalPoints);
	}

	useCountUp({
		ref: countUpRef,
		prefix: "Points: ",
		start: getStartPoints(),
		end: totalPoints,
		separator: ",",
		delay: 0,
		duration: duration
	});

	return (
		<div className="profile--header--points dark" ref={countUpRef} />
	);
};

UserPoints.propTypes = {
	duration: PropTypes.number,
	totalPoints: PropTypes.number,
	awardedPoints: PropTypes.number
}

UserPoints.defaultProps = {
	duration: 3,
	totalPoints: 1000,
	awardedPoints: undefined
}

export default UserPoints;