import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import constraints from './constraints';
import { validate } from 'validate.js';
import { buildClassList } from '../common/utils';

const UserSetupForm = ({ addUser, setErrorCount, handleSubmit, ...props }) => {

	return (
		<Formik
			initialValues={{
				name: '',
				email: '',
				emailConfirm: '',
				password: '',
				passwordConfirm: ''
			}}
			validate={(values) => {
				let errors = {};
				let validationResult = validate(values, constraints)

				for (const [field] of Object.entries(values)) {
					if (validationResult && validationResult[field]) {
						errors[field] = validationResult[field][0];
					}
				}
				setErrorCount(Object.keys(errors).length);
				return errors;
			}}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={(values) => {
				handleSubmit(values);
			}}
		>
			{({ errors }) => (
				<Form className="login--form" autoComplete="false" noValidate>
					<ConnectedFocusError />
					<span className={buildClassList(["instructor-add--input-group", "mb-2", errors.name ? "error" : ""])}>
						<label htmlFor="instructor-add--name">Name</label>
						<span className="instructor-add--input">
							<Field id="instructor-add--name"
								className="login--input"
								name="name"
								type="text"
								placeholder="Required" />

							<ErrorMessage className="form-error" name="name" />
						</span>
					</span>

					<span className={buildClassList(["instructor-add--input-group", "mb-2", errors.email ? "error" : ""])}>
						<label htmlFor="instructor-add--email">Email</label>
						<span className="instructor-add--input">
							<Field id="instructor-add--email"
								className="login--input"
								name="email"
								type="email"
								placeholder="Required" />

							<ErrorMessage className="form-error" name="email" />
						</span>
					</span>

					<span className={buildClassList(["instructor-add--input-group", "mb-2", errors.emailConfirm ? "error" : ""])}>
						<label htmlFor="instructor-add--emailConfirm">Confirm Email</label>
						<span className="instructor-add--input">
							<Field id="instructor-add--emailConfirm"
								className="login--input"
								name="emailConfirm"
								type="email"
								placeholder="Required" />

							<ErrorMessage className="form-error" name="emailConfirm" />
						</span>
					</span>

					<span className={buildClassList(["instructor-add--input-group", "mb-2", errors.password ? "error" : ""])}>
						<label htmlFor="instructor-add--password">Password</label>
						<span className="instructor-add--input">
							<Field id="instructor-add--password"
								className="login--input"
								name="password"
								type="password"
								autoComplete="new-password"
								placeholder="Required" />

							<ErrorMessage className="form-error" name="password" />
						</span>
					</span>

					<span className={buildClassList(["instructor-add--input-group", "mb-2", errors.passwordConfirm ? "error" : ""])}>
						<label htmlFor="instructor-add--passwordConfirm">Confirm Password</label>
						<span className="instructor-add--input">
							<Field id="instructor-add--passwordConfirm"
								className="login--input"
								name="passwordConfirm"
								type="password"
								autoComplete="new-password"
								placeholder="Required" />

							<ErrorMessage className="form-error" name="passwordConfirm" />
						</span>
					</span>

					<span className="instructor-add--input-group">
						<div id="password-policy">
							Passwords must meet the following criteria:
							<ul>
								<li>Must be 12 characters or longer</li>
								<li>Must include 1 or more lower case letters</li>
								<li>Must include 1 or more upper case letters</li>
								<li>Must include 1 or more digits</li>
								<li>Cannot match your email address</li>
							</ul>
						</div>
					</span>

					<div className="login--button--container">
						<div></div>
						<Button className="mt-2" variant="primary" type="submit">Submit</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default UserSetupForm;