import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const OverallProgress = ({ progress, animated, ...props }) => {

	// Since the old Motion plugin does not work in React 17, I'm animating the progress bar by starting it at zero and then
	// changing it to the actual progress value shortly after the component loads. This uses the natural transition built into
	// the Bootstrap progressbar that "animates" when the value changes.

	const [progressState, setProgressState] = useState(0);

	useEffect(() => {
		var timer;
		if (progress !== progressState) {
			timer = setTimeout(function () {
				setProgressState(progress);
			}, 250);
		}

		// This prevents "setState" from getting called after the component unmounts
		return () => {
			clearTimeout(timer);
		}
	}, [progressState, progress]);

	return (
		<div className="profile--header--overall-progress">
			<div className="progress">
				{/* TODO: check that use of aria labels is correct and that screen reader is not confused */}
				<div
					className={["progress-bar", animated && "progress-bar-animated progress-bar-striped"].filter(e => !!e).join(' ')}
					role="progressbar"
					style={{ width: progressState + "%" }}
					aria-valuenow={progressState}
					aria-valuemin="0"
					aria-valuemax="100"
					aria-labelledby="progress-label">

					{progressState + "%"}

				</div>
			</div>
			{/* Intentionally using progress here instead of progressState, because I do not want the screen reader value to change from 0 to the real value */}
			<div id="progress-label" className="sr-only">{"Current progress is " + progress + "%"}</div>
		</div>
	);
};

OverallProgress.propTypes = {
	progress: PropTypes.number,
	animated: PropTypes.bool
}

OverallProgress.defaultProps = {
	progress: 50,
	animated: false
}

export default OverallProgress;