import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useDidUpdate, useDidMount } from 'rooks';
import { hasSubmittedAnswer, removeAnswer, hasSubmittedAnswers } from './utils';
import QuizQuestion from './QuizQuestion';

const CheckboxQuizQuestion = ({ question, answers, condition, answerCountMinimum, answerCountLimit, storedAnswers, submittedAnswers, handleSubmit, activityHelper, isActivityDirty, ...props }) => {
	const [prerequisites, setPrerequisites] = useState({ conditionsNotMet: false, warning: '' });
	const [selectedAnswers, setSelectedAnswers] = useState([]);
	const [isDisableUnselected, setIsDisableUnselected] = useState(false);
	const isAtEndOfForm = (!activityHelper.requiresMultiPageForm() || (activityHelper.requiresMultiPageForm() && activityHelper.isAtLastView()));
	const submittedAnswer = hasSubmittedAnswers(submittedAnswers) ? submittedAnswers[question.questionNumber] : null;

	const hasStoredAnswer = () => {
		return storedAnswers !== null && typeof storedAnswers !== 'undefined' && storedAnswers.length > 0 && storedAnswers[question.questionNumber];
	};

	const getStoredAnswer = () => {
		return storedAnswers[question.questionNumber].answer;
	};

	const extractValue = () => {
		let answers = [];
		if (isActivityDirty && hasStoredAnswer()) {
			answers = getStoredAnswer();
		} else if (hasSubmittedAnswer(submittedAnswer) && submittedAnswer.answer) {
			answers = submittedAnswer.answer;
		} else if (hasStoredAnswer()) {
			answers = getStoredAnswer();
		}

		return answers.map((value) => value.toString());
	}

	const buildInitialValues = () => {
		let values = {};
		values[question.questionNumber.toString()] = extractValue();
		return values;
	}

	const handleChange = (event) => {
		let answer = event.target.value;
		let newSelectedAnswers = [...selectedAnswers];
		let answersArray = [];

		if (newSelectedAnswers.includes(answer)) {
			answersArray = removeAnswer(newSelectedAnswers, answer);
			newSelectedAnswers = answersArray;
		} else {
			newSelectedAnswers.push(answer);
		}

		setSelectedAnswers(newSelectedAnswers);
		activityHelper.markActivityDirty();
	}

	const checkNextButton = () => {
		if (hasSubmittedAnswer(submittedAnswer) && !isActivityDirty) {
			activityHelper.enableNextButton();
		} else {
			activityHelper.disableNextButton();
		}
	}

	const checkanswerCountLimit = () => {
		if (selectedAnswers.length >= answerCountLimit) {
			setIsDisableUnselected(true);
		} else {
			setIsDisableUnselected(false);
		}
	}

	const checkWarning = (answer) => {
		if (condition) {
			let requirementsMetArray = Array(condition.requirements.length).fill(false)
			let stringFlags = condition.flags.map(f => f.toString());

			for (let flag of stringFlags) {
				if (answer.includes(flag)) {
					for (let requirement of condition.requirements) {
						for (let part of requirement) {
							if (answer.includes(part.toString())) {
								requirementsMetArray[
									condition.requirements.indexOf(requirement)
								] = true
							}
						}
					}
					if (requirementsMetArray.includes(false)) {
						return setPrerequisites({conditionsNotMet: true, warning: condition.warning});
					}
				}
			}
			return setPrerequisites({conditionsNotMet: false, warning: ''});
		}
	}

	useDidUpdate(() => {
		checkanswerCountLimit();
		checkWarning(selectedAnswers);
		activityHelper.markActivityDirty();
	}, [selectedAnswers]);

	useDidUpdate(() => {
		checkNextButton();
	}, [isActivityDirty, submittedAnswer]);

	useDidMount(() => {
		setSelectedAnswers(extractValue());
		checkanswerCountLimit();
		checkNextButton();
	});

	return (
		<Formik
			initialValues={buildInitialValues()}
			validate={(values) => {
				const errors = {};
				if (values[question.questionNumber].length < answerCountMinimum) {
					errors[question.questionNumber] = `Please select at least ${answerCountMinimum} answer(s) and try again.`;
				}
				return errors;
			}}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={(values) => {
				if (prerequisites.conditionsNotMet) {
					return false;
				}
				
				if (isAtEndOfForm) {
					activityHelper.handleSubmit(values);
				} else {
					activityHelper.handleSubmit(values, false)
						.then(() => activityHelper.handleNext());
				}
			}}
		>
			{({ errors }) => (
				<Form>
					<QuizQuestion question={question} inputType="checkbox" answers={answers} selectedAnswers={selectedAnswers} condition={condition} warning={prerequisites.warning} conditionsNotMet={prerequisites.conditionsNotMet} handleChange={handleChange} submittedAnswer={submittedAnswer} isDisableUnselected={isDisableUnselected} errors={errors} isGateway={activityHelper.isGateway}/>
					<Button className="mt-2" variant="light" type="submit" disabled={submittedAnswers && !isActivityDirty}>{isAtEndOfForm ? "Submit" : "Continue"}</Button>
				</Form>
			)}
		</Formik>
	);
};

export default CheckboxQuizQuestion;