import React from 'react';
import PropTypes from 'prop-types';
import TextParagraph from './TextParagraph';
import './text-style.css';

const Text = ({ header, warning, paragraphs, ...props }) => {

	if (paragraphs) {
		return (
			<div className="text--container">
				{header &&
					<div className="text--header">{header}</div>}
				
				{paragraphs.map((paragraph, index) =>
						<TextParagraph key={index} text={paragraph.text} />)}
				
				{warning &&
					<div className="text--warning">{warning}</div>}
			</div>
		);
	} else {
		return null;
	}
};

Text.propTypes = {
	header: PropTypes.string,
	warning: PropTypes.string,
	paragraphs: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string
	}))
}

export default Text;