import GeneralView from '../Activity/View/GeneralView';
import IntroView from '../Activity/View/IntroView';
import VideoView from '../Activity/View/VideoView';
import TextView from '../Activity/View/TextView';
import GraphicView from '../Activity/View/GraphicView';
import GameView from '../Activity/View/GameView';
import GatewayQuizSummaryView from '../Activity/View/GatewayQuizSummaryView';
import EnterTextView from '../Activity/View/EnterTextView';
import ChooseOneView from '../Activity/View/ChooseOneView';
import InteractiveModelView from '../Activity/View/InteractiveModelView';
import LabellingView from '../Activity/View/LabellingView';
import OrderingView from '../Activity/View/OrderingView';
import IdentityPlanetView from '../Activity/View/IdentityPlanetView';
import InteractiveGraphicView from '../Activity/View/InteractiveGraphicView';
import SpinningWheelView from '../Activity/View/SpinningWheelView';
import SortingView from '../Activity/View/SortingView';
import QuizView from '../Activity/View/QuizView';
import { Error } from '../common/Error';

const useView = (view) => {

	const viewTypes = {
		video: VideoView,
		text: TextView,
		general: GeneralView,
		game: GameView,
		gatewayQuizSummary: GatewayQuizSummaryView,
		graphic: GraphicView,
		enterText: EnterTextView,
		intro: IntroView,
		chooseOne: ChooseOneView,
		interactiveModel: InteractiveModelView,
		labelling: LabellingView,
		ordering: OrderingView,
		identityPlanet: IdentityPlanetView,
		interactiveGraphic: InteractiveGraphicView,
		spinningWheel: SpinningWheelView,
		sorting: SortingView,
		quiz: QuizView
	}

	return viewTypes[view.type] ? viewTypes[view.type] : Error;
}

export default useView;