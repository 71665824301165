import React from 'react';
import { Popover } from 'react-bootstrap';
import IdentityPlanetQuestion from './IdentityPlanetQuestion';
import PropTypes from 'prop-types';

class ProfileModalPopover extends React.Component {
	buildPopoverBody = (props) => {
		if (Array.isArray(props.text)) {
			return props.text.map((question, index) => <IdentityPlanetQuestion key={index} question={question.question} answers={question.answers} />);
		} else {
			return <div className="profile--modal--popover--text">{props.text}</div>
		}
	}

	render() {
		const props = this.props;
		return (
			<Popover
				id={'popover--' + props.name}
				{...props}
			>
				<Popover.Header>{props.title}</Popover.Header>
				<Popover.Body>
					{this.buildPopoverBody(props)}
				</Popover.Body>
			</Popover>
		);
	}
}

ProfileModalPopover.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.any
}

ProfileModalPopover.defaultProps = {
	name: "Popover Name",
	title: "Popover Title",
	text: "Popover Text"
}

export default ProfileModalPopover;