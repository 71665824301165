export const constraints = {
    studentName: {
        presence: {
            allowEmpty: false,
            message: "^The Name field is empty; it is a required field and must be filled in."
        }
    },
    studentEmail: {
        presence: {
            allowEmpty: false,
            message: "^The Email field is empty; it is a required field and must be filled in."
        }
    }
}

export default constraints;