import React from 'react';
import { useHistory } from 'react-router';
import useTrophies from '../../hooks/useTrophies';
import { useAuth } from '../../common/ProvideAuth';
import { submitTrophySelection } from '../../api/trophy';
import { Loader } from '../../common/Loader';
import TrophyPage from './TrophyPage';
import './trophy-style.css';
import useTitle from '../../hooks/useTitle';

const TrophyContainer = ({  ...props }) => {

	const { trophies, isLoading, isError } = useTrophies();
	const auth = useAuth();
	const history = useHistory();

	useTitle('Vision of You Trophies Page');

	const saveTrophies = (trophies) => {
		submitTrophySelection(auth.user.authToken, trophies)
			.then(response => {
				history.replace("/");
			});
	}

	if (isLoading) {
		return (
			<div className="loader--container">
				<Loader />
			</div>
		)
	}

	if (isError) {
		return (
			<div className="loader--container">
				ERROR
			</div>
		)
	}

	return (
		<TrophyPage trophies={trophies} saveTrophies={saveTrophies} />
	);
};

export default TrophyContainer;