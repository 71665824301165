import useSWR from 'swr';
import API from '../api/api';
import { stringify } from 'qs';
import { useAuth } from '../common/ProvideAuth';

const useTrophies = () => {
	const auth = useAuth();

	const fetcher = url => API.post(url, stringify({authToken: auth.user.authToken, page: "trophies"}))
		.then((res) => {
			switch (res.data.code) {
				case 200:
					return res.data.trophies;
				default:
					auth.sessionTimeout();
					return {};
			}
		});

	const { data, error } = useSWR('/route', fetcher);

	return {
		trophies: data,
		isLoading: !error && !data,
		isError: error
	}
}

export default useTrophies;