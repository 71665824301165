import React from 'react';
import Ordering from './Ordering/Ordering';

const OrderingView = ({ view, storedAnswers, submittedAnswers, activityHelper, isActivityDirty, ...props }) => {

	/*
	* It's important to note that there is a disconnect between the values used for conditions/requirements in the question
	* activity and those used in the ordering activity. In the question activities, the requirements are checked based on the
	* index in an array, and thus the values must be zero-based. However, for the ordering, since not all the items from
	* the corresponding question are present, we must rely on the ID associated with each answer, which is one-based.
	*/
	let allReqs = [];
	let reqs = [];
	if (view.condition && view.condition.requirements) {
		reqs = view.condition.requirements;
		reqs.forEach(function (item, index) {
			allReqs = allReqs.concat(item);
		});
	}

	let allFlags = view.condition && view.condition.flags ? view.condition.flags : [];

	return (
		<Ordering question={view.questions[0]} endpoints={view.endPoints} answers={view.answers} requirements={allReqs} flags={allFlags} 
			warningText={view.condition.warning} activityHelper={activityHelper} 
			isActivityDirty={isActivityDirty} submittedAnswers={submittedAnswers} />
	);
};

export default OrderingView;