import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { fetchActivity, submitAnswer } from '../api/activity';
import { Loader } from '../common/Loader';
import { Error } from '../common/Error';
import { useAuth } from '../common/ProvideAuth';
import { useDidMount, useDidUpdate } from 'rooks';
import TopicPage from './TopicPage';


const TopicContainer = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();
	const [topic, setTopic] = useState(null);
	const [loadingState, setLoadingState] = useState({ loading: false, fetched: false, activity: auth.currentActivity });

	useDidMount(() => {
		openActivity();
	});

	useDidUpdate(() => {
		loadActivity(auth.currentActivity);
	}, [auth.currentActivity]);

	/*
	 * There are some activities (like Gateway Quizzes), where the same activityId is fetched
	 * repeatedly, and the server returns a different question view from within that activity.
	 * This renders listening only for changes on auth.currentActivity in useDidUpdate above
	 * insufficient for triggering openActivity(). Therefore, I added an activity value to
	 * loadingState and listen for changes to that to determine when to call openActivity.
	 */
	useDidUpdate(() => {
		if (!loadingState.loading && !loadingState.fetched && loadingState.activity) {
			openActivity();
		}
	}, [loadingState]);

	const isLoading = () => {
		return loadingState.loading && !loadingState.fetched;
	};

	const closeActivity = () => {
		auth.viewActivity(null);
		history.replace("/");
	};

	const loadActivity = (activityId) => {
		setLoadingState({ loading: false, fetched: false, activity: activityId });
	}

	/*
	 * If we're in a Gateway Quiz, for example, the same activityId will be requested multiple times.
	 * If we simply call auth.viewActivity() and the activityId has not changed, it will not trigger
	 * a listener on auth.currentActivity. This is why this function manually updates loadingState
	 * to force the listener documented above to trigger.
	 * 
	 * TODO: see if there is a way I can just get rid of auth.currentActivity, because at this point,
	 * I believe it's sole use is to trigger loading /activity from the Profile page (and vice versa).
	 * There has to be a better way to get an initial activityId value from the ProfilePage to here while
	 * initially navigating to /activity.
	 */
	const gotoActivity = (activityId) => {
		if (activityId === auth.currentActivity) {
			loadActivity(activityId);
		} else {
			auth.viewActivity(activityId);
		}
	};

	const submitActivityAnswer = (answer) => {
		return submitAnswer(auth.user.authToken, topic.activity.id, answer)
			.then((response) => {
				if (response.data.code !== 200) {
					auth.sessionTimeout();
				} else {
					const results = response.data.results;
					const updatedActivity = { ...topic.activity, next: results.nextActivity, submittedAnswers: results.submittedAnswers };
					setTopic({ ...topic, activity: updatedActivity });

					return new Promise(function(resolve, reject) {
						resolve(results.feedback);
					});
				}
			});
	}

	/*
	 * Unfortunately, there is nothing inherently identifiable in questions that exist within a view. This is problematic,
	 * because an activity can have a mix of question and non-question views, which makes the submittedAnswers array contained
	 * in an activity have indices that do not match the view index within the activity. This happens because answers are submitted
	 * and stored only for questions and not other activities. So if I have an activity that starts with an intro view and then has
	 * an EnterText view, the stored answer for the second view will be at index 0. I figure it's easiest if I just seed the
	 * index that would naturally shake out once each time an activity is loaded and then not have to worry about it anymore.
	 */
	const seedQuestionNumbers = (topic) => {
		const activity = topic.activity;
		const views = activity.views;
		let questionNumber = 0;
		for (let view of views) {
			if (view.questions) {
				for (let question of view.questions) {
					question.questionNumber = questionNumber++;
				}
			}
		}

		activity.questionCount = questionNumber;
	}

	const openActivity = () => {
		let activityId = loadingState.activity;

		if (!auth.user.authToken || !activityId) {
			auth.sessionTimeout();
		} else {
			setLoadingState({ ...loadingState, loading: true });

			fetchActivity(activityId, auth.user.authToken)
				.then((response) => {
					switch (response.data.code) {
						case 200:
							let topicResponse = response.data.activity;
							seedQuestionNumbers(topicResponse);
							setTopic(topicResponse);
							break;

						default:
							auth.sessionTimeout();
							break;
					}
				}).finally(() => {
					setLoadingState({ ...loadingState, loading: false, fetched: true });
				});
		}
	};

	return (
		<>
			{isLoading() &&
				<div className="activity--loader--container">
					<Loader />
				</div>
			}

			{!isLoading() && topic === null &&
				<Error error="activity" />
			}

			{!isLoading() && topic != null &&
				<TopicPage topic={topic} closeActivity={closeActivity} gotoActivity={gotoActivity} submitActivityAnswer={submitActivityAnswer} />
			}
		</>
	);
};

export default TopicContainer;