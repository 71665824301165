import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { buildClassList } from '../../../common/utils';
import './interactive-graphic-style.css';
import InteractiveGraphicOverlay from './InteractiveGraphicOverlay';
import InteractiveGraphicPopover from './InteractiveGraphicPopover';
import { Button, Modal } from 'react-bootstrap';
import PlainModalBody from './PlainModalBody';
import SelectionModalBody from './SelectionModalBody';
import { CheckLg } from 'react-bootstrap-icons';
import { useDidMount } from 'rooks';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';

const InteractiveGraphic = ({ prompt, image, imageAlt, subtype, handleRedirect, handleSelection, hotspots, allowHighlighting, submittedSelection, activityHelper, supplemental, ...props }) => {

	const [highlightIncomplete, setHighlightIncomplete] = useState(false);

	const [modalState, setModalState] = useState({ modalOpen: false, title: "", image: "", text: "" });
	const [selection, setSelection] = useState(submittedSelection);

	useDidMount(() => {
		if (subtype === 'redirect' && !activityHelper.hasNextView()) {
			activityHelper.disableNextButton();
		}
	});

	const modalOpen = (event, title, image, text) => {
		event.preventDefault();
		setModalState({ modalOpen: true, title: title, image: image, text: text });
	}

	const modalClose = () => {
		setModalState({ modalOpen: false, title: "", image: "", text: "" });
	}

	const toggleHighlight = () => {
		setHighlightIncomplete(!highlightIncomplete);
	}

	const buildHotspotBorder = (hotspot) => {
		return {
			position: 'absolute',
			left: hotspot.coords[0],
			top: hotspot.coords[1],
			width: hotspot.coords[2] - hotspot.coords[0],
			height: hotspot.coords[3] - hotspot.coords[1]
		};
	}

	const makeSelection = () => {
		setSelection(modalState.text.name);
		handleSelection(modalState.text.name)
			.then((response) => {
				modalClose();
			});
	}

	const handleRedirectClick = (event) => {
		event.preventDefault();
		handleRedirect(event.currentTarget.id);
	}

	const makeRedirectHotspot = (hotspot, index) => {
		return (<a key={index}
			href={hotspot.link}
			id={hotspot.link}
			className={buildClassList(['interactive-graphic--hotspot', highlightIncomplete && !hotspot.completed ? 'interactive-graphic--highlight' : '', hotspot.completed ? 'complete' : 'incomplete'])}
			onClick={handleRedirectClick}
			style={buildHotspotBorder(hotspot)}>

			{!hotspot.completed && <span className="sr-only">{hotspot.name}</span>}
			{hotspot.completed && <span className="sr-only">{`Completed; ${hotspot.name}`}</span>}
			{hotspot.completed && <CheckLg className="completed--glyph text-success" />}
		</a>);
	}

	return (
		<>
			<div>
				<div className="interactive-graphic--container">
					<div className="interactive-graphic--prompt">{prompt}</div>
					<div className="interactive-graphic">
						<img
							className="interactive-graphic--background-layer"
							src={process.env.REACT_APP_URL + '/assets/images/activities' + image}
							alt={imageAlt ? imageAlt : prompt}
						/>

						{subtype === 'selection' &&
							hotspots.map((hotspot, index) => {
								const isSelected = selection != null && selection === hotspot.text.name;
								const characterLabel = "Character: " + hotspot.text.name + (isSelected ? '; You have selected this character' : '');
								return (<button key={index}
									className={buildClassList(['interactive-graphic--hotspot', 'incomplete', isSelected ? 'interactive-graphic--highlight' : ''])}
									onClick={(e) => modalOpen(e, null, hotspot.image, hotspot.text)}
									style={buildHotspotBorder(hotspot)}>

									<span className="sr-only">{characterLabel}</span>
								</button>);
							})
						}

						{subtype === 'modal' &&
							hotspots.map((hotspot, index) =>
								<a key={index}
									href={hotspot.image}
									className={buildClassList(['interactive-graphic--hotspot', 'incomplete'])}
									onClick={(e) => modalOpen(e, hotspot.title, hotspot.image, hotspot.text)}
									style={buildHotspotBorder(hotspot)}>

									<span className="sr-only">{hotspot.title}</span>
								</a>
							)
						}

						{subtype === 'redirect' &&
							hotspots.map((hotspot, index) => makeRedirectHotspot(hotspot, index))
						}

						{subtype === 'popover' &&
							hotspots.map((hotspot, index) => {
								const hotspotName = hotspot.name.replace(' ', '-');
								return (
									<InteractiveGraphicOverlay
										key={index}
										name={hotspotName}
										coords={hotspot.coords}
										overlay={<InteractiveGraphicPopover key={index} name={hotspotName} text={hotspot.text} label={hotspot.label} />}
									/>
								);
							})
						}
					</div>

					{supplemental &&
						<ActivitySupplementalInfo label={supplemental.label} content={supplemental.content} />
					}
				</div>

				{allowHighlighting &&
					<span className="interactive-graphic--highlight-remaining">
						<input id="highlight-remaining" name="highlight-remaining" type="checkbox" checked={highlightIncomplete} onChange={toggleHighlight} />
						<span className="highlight-label"><label htmlFor="highlight-remaining">Highlight remaining selection(s)</label></span>
					</span>
				}
			</div>

			<Modal className="interactive-graphic--modal" show={modalState.modalOpen} onHide={modalClose}>
				{modalState.title &&
					<Modal.Header>
						<h1>{modalState.title}</h1>
					</Modal.Header>
				}

				<Modal.Body>
					{subtype === 'modal' &&
						<PlainModalBody title={modalState.title} image={modalState.image} text={modalState.text} />
					}

					{subtype === 'selection' &&
						<SelectionModalBody image={modalState.image} name={modalState.text.name} bio={modalState.text.bio} goals={modalState.text.goals} />
					}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={modalClose}>Close</Button>
					{subtype === 'selection' &&
						<Button variant="primary" onClick={makeSelection}>Select</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

InteractiveGraphic.propTypes = {
	prompt: PropTypes.string,
	image: PropTypes.string,
	imageAlt: PropTypes.string,
	handleRedirect: PropTypes.func,
	subtype: PropTypes.oneOf(['redirect', 'popover', 'modal', 'selection']),
	hotspots: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		image: PropTypes.string,
		text: PropTypes.any,
		coords: PropTypes.arrayOf(PropTypes.number),
		link: PropTypes.string,
		completed: PropTypes.bool
	})),
	allowHighlighting: PropTypes.bool
}

InteractiveGraphic.defaultProps = {
	allowHighlighting: false,
	hotspots: []
}

export default InteractiveGraphic;