import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { buildClassList } from '../../../common/utils';
import { hasSubmittedAnswer } from '../Quiz/utils';
import { Form, Formik, ErrorMessage } from 'formik';
import ChooseOneAnswer from './ChooseOneAnswer';
import { useDidUpdate, useDidMount } from 'rooks';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';
import useSubmitFeedback from '../../../hooks/useSubmitFeedback';


const ChooseOneQuestion = ({ questionNumber, style, image, imageAlt, supplemental, text, answers, isGateway, handleSubmit, submittedAnswer, buttonHelper, ...props }) => {

	const [submitFeedback] = useSubmitFeedback(submittedAnswer);
	const gateway = isGateway();

	const checkNextButton = () => {
		if (hasSubmittedAnswer(submittedAnswer)) {
			buttonHelper.enableNextButton();
		} else {
			buttonHelper.disableNextButton();
		}
	};

	// Formik requires string values for radio buttons
	const getSubmittedValue = () => {
		if (hasSubmittedAnswer(submittedAnswer)) {
			return submittedAnswer.answer[0].toString();
		}
		return '';
	}

	useDidMount(() => {
		checkNextButton();
	});

	useDidUpdate(() => {
		checkNextButton();
	}, [submittedAnswer]);

	return (
		<div className="choose-one--question">
			{image &&
				<img className={buildClassList(["choose-one--image", style ? "choose-one--image--" + style : ""])}
					src={process.env.REACT_APP_URL + '/assets/images/activities/' + image} alt={imageAlt} />
			}

			{supplemental &&
				<ActivitySupplementalInfo label={supplemental.label} content={supplemental.content} />
			}

			{text &&
				<div className="choose-one--text">{text}</div>
			}

			<div className="mt-2 choose-one--answers">
				<Formik
					initialValues={{
						'0': hasSubmittedAnswer(submittedAnswer) ? getSubmittedValue() : ''
					}}
					validate={(values) => {
						const errors = {};
						if (!values['0']) {
							errors['0'] = "Please select an answer and try again.";
						}
						return errors;
					}}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
				>
					<Form>
						{text &&
							<div id="legend" className="sr-only">{text}</div>
						}

						{image && !text &&
							<div id="legend" className="sr-only">Which answer best fits the image?</div>
						}

						{!text && !image &&
							<div id="legend" className="sr-only">We need a solution for when there is no image and no text!</div>
						}

						<ul role="radiogroup" aria-labelledby="legend">
							{answers.map((answer, index) =>
								<li key={`${answer}-${index}`}><ChooseOneAnswer radioGroupName="0" answerNumber={index} text={answer.text} image={answer.image} imageAlt={answer.imageAlt} submittedAnswer={submittedAnswer} gateway={gateway} /></li>)
							}
						</ul>
						<span className="error form-error" aria-live="polite">
							<ErrorMessage name="picked" />
						</span>

						<span className="sr-only" aria-live="polite">
							{submitFeedback &&
								<span>{submitFeedback}</span>
							}
						</span>

						<Button className="mt-2" variant="light" type="submit" disabled={submittedAnswer}>Submit</Button>
					</Form>

				</Formik>
			</div>
		</div>
	);
};

ChooseOneQuestion.propTypes = {
	questionNumber: PropTypes.number,
	style: PropTypes.string,
	image: PropTypes.string,
	text: PropTypes.string,
	answers: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.string,
		text: PropTypes.string
	})),
	submittedAnswer: PropTypes.object
}

export default ChooseOneQuestion;