import React, { useState } from 'react';
import { DEFAULT_TYPES, DEFAULT_PROPS } from './default-character';
import { Check2, X } from 'react-bootstrap-icons';
import { Button, Modal } from 'react-bootstrap';
import ProfileCharacterModalBody from './ProfileCharacterModalBody';

const ProfileCharacter = ({ name, image, group, bio, goals, ...props }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const EMPTY_CHARACTER_GOALS = <div>
		<div className="profile--character--goal--text">
			In the Identity unit, you will choose a character that will be with you throughout the Vision of You program.
		</div>

		<br />

		<div className="profile--character--goal--text">
			Each character has a unique set of goals.
			Each goal takes points in order to accomplish, and you will earn points by completing activities within the program. Earn more points by making healthy decisions.
		</div>
	</div>

	const buildGoals = () => {
		return goals.map((goal, index) => {
			return (<div key={'goal-' + index} className={goal.completed ? "profile--character--goal profile--character--goal--completed" : "profile--character--goal"}>
				<div className="profile--character--goal--text">
					{goal.completed && <Check2 className="profile--character--goal--glyph complete" aria-label={'Goal ' + (index + 1) + ' Complete'} />}
					{!goal.completed && <X className="profile--character--goal--glyph incomplete" aria-label={'Goal ' + (index + 1) + ' Incomplete'} />}
					{goal.pointsRequired.toLocaleString()} points - {goal.text}
				</div>
			</div>);
		});
	}

	if (!image) {
		return (
			<div className="profile--character--container">
				<div className="profile--character--image--container">
				</div>
			</div>
		);
	}

	const buildAriaLabel = () => {
		if (group === 1) {
			return `This is an image of ${name}, which you have chosen as your character for Vision of You`;
		} else {
			return "Character image. Choose a character in the 'Meet the Group' topic. An image will show up here once a character has been selected.";
		}
	}

	return (
		<>
			<h2 id="profile-character-heading" className="sr-only">Profile Character</h2>
			<div className="profile--character--container" role="region">
				<div className="profile--character--image--container dark" aria-labelledby="character-image-header">
					<h3 id="character-image-header" className="sr-only">Your Character's Image</h3>
					<button role="link" onClick={handleShow}>
						<img className="profile--character--image"
							src={`${process.env.REACT_APP_URL}/assets/images/characters${image}`}
							alt={buildAriaLabel()} />
					</button>
				</div>

				<div className="profile--character--goals dark">
					<h3 className="sr-only">Your Character's Goals</h3>
					<div className="profile--character--goals--name">
						{group > 0 ?
								<span>{name}'s Goals:</span>
							: <span>Character Goals:</span>
						}
					</div>

					<div className="profile-character--goal">Complete activities to earn points and help {name} meet their goals.</div>
					
					{group > 0 && goals.length > 0 && buildGoals()}

					{(group === 0 || goals.length === 0) && EMPTY_CHARACTER_GOALS}
				</div>
			</div>

			<Modal className="profile--character--modal" show={show} onHide={handleClose}>
				<Modal.Body>
					<ProfileCharacterModalBody name={name} image={image} bio={bio} goals={goals} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

ProfileCharacter.propTypes = DEFAULT_TYPES;

ProfileCharacter.defaultProps = DEFAULT_PROPS;

export default ProfileCharacter;