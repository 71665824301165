import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import './login-style.css';

const LoginForm = ({doLogin, loginWarning, ...props}) => {
	const [loginError, setLoginError] = useState(loginWarning || '');

	return (
		<div className="login--form--container">
			<Formik
				initialValues={{
					username: '',
					password: ''}}
				onSubmit={(values, { setSubmitting }) => {
					doLogin(values.username, values.password)
						.then(response => {
						})
						.catch(error => {
							setLoginError(error);
						});
				}}
			>

				<Form className="login--form">
					<label htmlFor="username">Username</label>
					<Field 
						className="login--input" 
						id="username"
						name="username"
						type="text" />

					<label htmlFor="password">Password</label>
					<Field 
						className="login--input" 
						id="password"
						name="password"
						type="password" />

					{ loginError &&
						<div className="login--error--status">{loginError}</div>
					}
					<div className="login--button--container">
						<Container>
							<Row xs="1" sm="2">
								<Col xs={{ order: 'last' }} className="px-0"><Button className="float-end" variant="primary" type="submit">Log In</Button></Col>
								<Col xs={{ order: 'first' }} className="px-0"><Button className="float-start px-0" variant="link" size="sm" href="/password">Forgot Password</Button></Col>
							</Row>
						</Container>
					</div>
				</Form>

			</Formik>
		</div>
	);
};


LoginForm.propTypes = {
	loginWarning: PropTypes.string,
	doLogin: PropTypes.func
};

LoginForm.defaultProps = {
	loginWarning: '',
	doLogin: function (username, password) {
		return new Promise((resolve, reject) => {
			resolve();
		})
	}
};

export default LoginForm;