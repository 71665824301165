import React from 'react';
import PropTypes from 'prop-types';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';

const Game = ({ title, src, supplemental, ...props }) => {

	return (
		<div className="game--container">
			<iframe
				title={title}
				src={process.env.REACT_APP_URL + src}
				width="1000"
				height="560"
				frameBorder="0"
				allowFullScreen
			></iframe>
			{supplemental &&
				<ActivitySupplementalInfo label={supplemental.label} content={supplemental.content} />
			}
		</div>
	);
};

Game.propTypes = {
	title: PropTypes.string,
	src: PropTypes.string
}

export default Game;