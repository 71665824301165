import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import useModal from '../hooks/useModal';

export const Logout = ({ logoutHandler, ...props }) => {

	const { show, handleClose, handleShow } = useModal();

	return (
		<>
			<Button className="header--button" variant="danger" onClick={handleShow}>LOGOUT</Button>

			<Modal className="logout-modal" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Confirm Logout</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to logout?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>No</Button>
					<Button variant="primary" onClick={logoutHandler}>Yes</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

Logout.propTypes = {
	logoutHandler: PropTypes.func
}

Logout.defaultProps = {
	logoutHandler: function (event) {
	}
}