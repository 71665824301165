import React from 'react';
import { Popover } from 'react-bootstrap';

class InteractiveGraphicPopover extends React.Component {

	render() {
		const props = this.props;
		const ariaLabel = props.label ? props.label : props.text;
		return (
			<Popover id={'popover--' + props.name} {...props}>
				<Popover.Header aria-label={ariaLabel}>{props.name}</Popover.Header>
				<Popover.Body>
					<div className="interactive-graphic--popover--text">{props.text}</div>
				</Popover.Body>
			</Popover>
		);
	}
}

export default InteractiveGraphicPopover;