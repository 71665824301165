import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ProfileModalPopover from './ProfileModalPopover';
import ProfileModalOverlay from './ProfileModalOverlay';
import useModal from '../../hooks/useModal';

const ProfileModal = ({ id, name, icon, image, link, popovers, ...props }) => {

	const { show, handleClose, handleShow } = useModal();

	let modalPopovers = []
	if (popovers) {
		popovers.map((popover, i) =>
			modalPopovers.push({
				name: popover.name,
				coords: popover.coords,
				element: <ProfileModalPopover key={i} name={popover.name} title={popover.title} text={popover.text} />
			})
		)
	}

	return (
		<>
			<div className="profile--modal--icon--container">
				<button role="link" onClick={handleShow}>
					<img className="profile--modal--icon" src={`${process.env.REACT_APP_URL}/assets/images/app${icon}`} alt={name} />
				</button>
			</div>

			<Modal className="profile--modal" show={show} onHide={handleClose}>
				{image &&
					<Modal.Body>
						<div className="profile--modal--image--container">
							<img className="profile--modal--image" src={`${process.env.REACT_APP_URL}/assets/images/app${image}`} alt={name} />

							{modalPopovers.length > 0 &&
								modalPopovers.map((overlay, index) =>
									<ProfileModalOverlay key={index} name={overlay.name} coordinates={overlay.coords} element={overlay.element} />
								)
							}
						</div>
					</Modal.Body>
				}

				{!image && link &&
					<Modal.Body>
						<iframe className="profile--modal--iframe" src={`${process.env.REACT_APP_URL}/assets/pdfs${link}`} title={name}></iframe>
					</Modal.Body>
				}

				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ProfileModal;