import React, { useContext, createContext } from 'react';
import API from '../api/api';
import { stringify } from 'qs';
import { useSessionstorageState } from 'rooks';


const authContext = createContext();

const ProvideAuth = ({children}) => {
	const auth = useProvideAuth();

	return (
		<authContext.Provider value={auth}>
			{children}
		</authContext.Provider>
	);
}

export const TestProvideAuth = ({user, children}) => {
	const auth = useProvideAuth();
	auth.user = user;

	return (
		<authContext.Provider value={auth}>
			{children}
		</authContext.Provider>
	);
}

export const useAuth = () => {
	return useContext(authContext);
}

function useProvideAuth() {
	const [user, setUser] = useSessionstorageState("user", null);
	const [logoutReason, setLogoutReason] = useSessionstorageState("logoutReason", "");
	const [currentActivity, setCurrentActivity] = useSessionstorageState("currentActivity", null);
	

	const doLogin = (username, password) => {
		setLogoutReason("");
		return new Promise((resolve, reject) => {
			API.post("/login", stringify({username: username, password: password}))
				.then(function(response) {
					switch (response.data.code) {
						case 200:
							const userObject = {
								authToken: response.data.authResponse.authToken, 
								isInstructor: response.data.authResponse.isInstructor, 
								studentId: response.data.authResponse.studentId,
								welcomeVideo: response.data.authResponse.welcomeVideo,
								welcomeVideoSeen: response.data.authResponse.welcomeVideoSeen
							};
							sessionStorage.clear();
							setUser(userObject);
			
							resolve();
							break;

						case 1204:
						case 1203:
						case 1202:
							reject("Incorrect username/password or your class/account has been deactivated. Please contact your instructor for assistance.");
							break;

						default:
							reject("An unknown error occurred during login");
					}
				})
				.catch(function(error) {
					reject("An unknown error occurred during login");
				});
		});
	};

	const markWelcomeVideoSeen = () => {
		setUser({...user, welcomeVideoSeen: true});
	};

	const doLogout = () => {
		setLogoutReason("");
		return new Promise((resolve, reject) => {
			API.post("/logout", stringify({authToken: user.authToken}))
				.finally(() => {
					setUser(null);
					sessionStorage.clear();
				});
		});
	};

	const fetchProfile = () => {
		return new Promise((resolve, reject) => {
			let authToken = user.authToken;
			if (!authToken) {
				sessionTimeout();
				reject("Logged Out!");
			}

			API.post("/route", stringify({authToken: authToken, page: "profile"}))
				.then(function(response) {
					switch (response.data.code) {
						case 200:
							setUser({...user, profile: response.data.profile});
							resolve();
							break;

						default:
							sessionTimeout();
							reject("Could not fetch profile");
					}
				});
		});
	};

	const sessionTimeout = () => {
		sessionStorage.clear();
		setLogoutReason("You're session has expired. Please login again.");
		setCurrentActivity(null);
		setUser(null);
	};

	const checkLogin = async () => {
		let authToken = sessionStorage.getItem('authToken');
		if (authToken) {
			try {
				const authResponse = await API.post("/checkLogin", stringify({authToken: authToken}));
				if (authResponse.status === 200) {
					return authResponse.data;
				}
				return false;
			} catch (err) {
				console.error("Failed to check login", err);
				return false;
			}
		} else {
			return false;
		}
	};

	const viewActivity = (activityId) => {
		setCurrentActivity(activityId);
	}

	return {
		user,
		logoutReason,
		currentActivity,
		sessionTimeout,
		doLogin,
		doLogout,
		checkLogin,
		fetchProfile,
		viewActivity,
		markWelcomeVideoSeen
	};
}

export default ProvideAuth;