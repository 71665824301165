import React from 'react';
import PropTypes from 'prop-types';

const Intro = ({ text, ...props }) => {

	return (
		<div className="topic--activity--intro">
			<p>{text}</p>
		</div>
	);
};

Intro.propTypes = {
	text: PropTypes.string
}

export default Intro;