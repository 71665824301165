import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightCircleFill, ArrowLeftCircleFill } from 'react-bootstrap-icons';

const SortingOption = ({ image, text, questionNumber, answerNumber, position, handleChange, ...props }) => {

	const makeArrow = () => {
		if (position === 'arrow-left') {
			return <ArrowLeftCircleFill height="64" />
		} else {
			return <ArrowRightCircleFill height="64" />
		}
	}

	return (
		<div className="sorting--item">
			<input id={`${questionNumber}-${answerNumber}`}
				className={`sorting--item--input sorting--item--input--${position}`}
				onChange={handleChange}
				type="radio"
				name={questionNumber}
				value={answerNumber}
			/>
			<label className={`sorting--item--label sorting--item--label--${position}`} htmlFor={`${questionNumber}-${answerNumber}`}>{makeArrow()}</label>

			<div className="sorting--item--image--container">
				<img className="sorting--item--image"
					src={`${process.env.REACT_APP_URL}/assets/images/activities${image}`}
					alt={text} />
			</div>
		</div>
	);
};

SortingOption.propTypes = {
	image: PropTypes.string,
	text: PropTypes.string,
	questionNumber: PropTypes.number,
	answerNumber: PropTypes.number,
	position: PropTypes.string,
	handleChange: PropTypes.func
}

export default SortingOption;