import { useState } from "react";

const useSpinningWheel = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isWheelSpinning, setIsWheelSpinning] = useState(false);

	return {
		currentIndex,
		setCurrentIndex,
		isWheelSpinning,
		setIsWheelSpinning
	}
}

export default useSpinningWheel;