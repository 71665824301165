import React from 'react';
import PropTypes from 'prop-types';
import { buildClassList } from '../common/utils';
import { XLg, CheckLg } from 'react-bootstrap-icons';
import { Field } from 'formik';
import useRadioAnswer from '../hooks/useRadioAnswer';

const QuestionAnswerBubble = ({ inputGroupName, answerNumber, inputType, answerText, submittedAnswer, handleAnswerSelected, forceDisable, gateway, ...props }) => {
	
	const {answerId, isCorrectAnswerProvided, isAnswerCorrect, isDisabled, isShowCorrectGlyph, isShowIncorrectGlyph} = 
		useRadioAnswer(inputGroupName, answerNumber, submittedAnswer, gateway);

	const answerLabel = (isShowCorrectGlyph ? "Correct Answer: " : "") + (isShowIncorrectGlyph ? "Incorrect Answer: " : "") + answerText;
	
	return (
		<div className={buildClassList(["answer-bubble", submittedAnswer ? "submitted" : "", isCorrectAnswerProvided ? (isAnswerCorrect ? "correct" : "incorrect") : ""])}>
			<div className="feedback-container">
				{isShowCorrectGlyph &&
					<CheckLg className="glyph correct" />
				}

				{isShowIncorrectGlyph &&
					<XLg className="glyph incorrect" />
				}
			</div>
			<Field id={answerId}
				type={inputType}
				onClick={handleAnswerSelected}
				value={`${answerNumber}`}
				name={inputGroupName}
				aria-label={answerLabel}
				disabled={forceDisable || isDisabled} />
			<label htmlFor={answerId}>{answerText}</label>
		</div>
	);
};

QuestionAnswerBubble.propTypes = {
	checked: PropTypes.bool,
	inputGroupName: PropTypes.string,
	answerNumber: PropTypes.number,
	inputType: PropTypes.oneOf(['checkbox', 'radio']),
	answerText: PropTypes.string,
	submittedAnswer: PropTypes.any,
	handleAnswerSelected: PropTypes.func
}

QuestionAnswerBubble.defaultProps = {
}

export default QuestionAnswerBubble;