import PropTypes from 'prop-types';

export const DEFAULT_TYPES = {
	name: PropTypes.string,
	image: PropTypes.string,
	group: PropTypes.number,
	bio: PropTypes.string,
	goals: PropTypes.array
};

export const DEFAULT_PROPS = {
	name: "Your character",
	image: "/characters.png",
	group: 0,
	bio: "Your character will be with you throughout the Vision of You program, and you will help them make healthy decisions so they can achieve their goals. When you are able to select a character, pick one with goals similar to yours, or whose background you can relate to, or someone you think is interesting.",
	goals: [
		{
			"text": "Each character has a unique set of goals",
			"pointsRequired": 10000,
			"completed": false
		},
		{
			"text": "Each goal takes points in order to accomplish",
			"pointsRequired": 50000,
			"completed": false
		},
		{
			"text": "Earn points by completing activities within Vision of You program",
			"pointsRequired": 100000,
			"completed": false
		},
		{
			"text": "Earn more points by selecting healthy decisions",
			"pointsRequired": 200000,
			"completed": false
		}
	]
};