import React from 'react';
import PropTypes from 'prop-types';
import ChooseOne from './ChooseOne/ChooseOne';

const ChooseOneView = ({ view, submittedAnswers, activityHelper, ...props }) => {

	if (view.questions.length > 1) {
		alert('Unexpected multiple questions. Please report this activity to David.');
	}

	const question = view.questions[0];
	const submittedAnswer = submittedAnswers ? submittedAnswers[question.questionNumber] : null;

	return (
		<ChooseOne prompt={view.prompt} question={question} answers={view.answers} isGateway={activityHelper.isGateway} handleSubmit={activityHelper.handleSubmit} submittedAnswer={submittedAnswer} buttonHelper={{enableNextButton: activityHelper.enableNextButton, disableNextButton: activityHelper.disableNextButton}} />
	);
};

ChooseOneView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['chooseOne']),
		...ChooseOne.propTypes
	}).isRequired
}

ChooseOneView.defaultProps = {
}

export default ChooseOneView;