import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Logout } from './Logout';
import { useAuth } from './ProvideAuth';

export const Header = ({ isLoggedIn, logoutHandler, ...props }) => {
	let history = useHistory();
	let auth = useAuth();

	if (!isLoggedIn) {
		return null;
	}

	let doLogout = () => {
		auth.doLogout()
			.then(() => {
				history.replace("/login");
			});
	}

	const handleLogoClick = () => {
		auth.viewActivity(null);
		history.replace("/");
	}

	return (
		<header className="dark">
			<div className="header--container">
				<button role="link" onClick={handleLogoClick} aria-label="Click here to go home">
					<img className="header--logo" src={process.env.REACT_APP_URL + '/assets/images/app/logo-horizontal.png'} alt="Click here to go home" />
				</button>

				{isLoggedIn &&
					<a href="#main-content" className="skip-main" >Skip to Content</a>
				}

				<div className="header--right--container">
					<Logout logoutHandler={doLogout} />
				</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	isLoggedIn: PropTypes.bool,
	logoutHandler: PropTypes.func
}

Header.defaultProps = {
	isLoggedIn: false,
	logoutHandler: function (event) {
	}
}