import React from 'react';
import PropTypes from 'prop-types';
import TopicHeader from './TopicHeader';
import './topic-style.css';
import TopicProgress from './TopicProgress';
import ActivityContainer from './ActivityContainer';

const TopicPage = ({ topic, closeActivity, gotoActivity, submitActivityAnswer, ...props }) => {
	return (
		<div className="topic--container">
			<TopicHeader unitNumber={topic.unitNum} unitTitle={topic.unitTitle} topicTitle={topic.topicTitle} handleClose={closeActivity} />
			<ActivityContainer unit={topic.unitTitle} topic={topic.topicTitle} activity={topic.activity} gotoActivity={gotoActivity} closeActivity={closeActivity} submitActivityAnswer={submitActivityAnswer} />
			<TopicProgress progress={topic.topicProgress} />
		</div>
	);
};

TopicPage.propTypes = {
	topic: PropTypes.shape({
		unitNum: PropTypes.number,
		unitTitle: PropTypes.string,
		topicNum: PropTypes.number,
		topicTitle: PropTypes.string,
		topicProgress: PropTypes.number
	}).isRequired,
	closeActivity: PropTypes.func
}

export default TopicPage;