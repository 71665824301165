import API from './api';
import { stringify } from 'qs';

export async function checkLogin() {
	let authToken = sessionStorage.getItem('authToken');
	if (authToken) {
		const authResponse = await API.post("/checkLogin", stringify({authToken: authToken}));
		if (authResponse.status === 200) {
			return authResponse.data;
		}
		return false;
	} else {
		return false;
	}
}

export const fetchPasswordResetRequest = (refId) => {
	return API.post("/fetchPasswordResetRequest", stringify( { refId: refId } ));
}

export const changePassword = (refId, studentId, password, passwordConfirm) => {
	return API.post("/changePassword", stringify( { refId: refId, studentId: studentId, password: password, passwordConfirm: passwordConfirm } ));
}

export const forgotPassword = (email) => {
	return API.post("/forgotPassword", stringify( { email: email } ));
}