import { useState } from "react";
import { useDidUpdate } from "rooks";

const useSubmitFeedback = (submittedAnswer) => {
	const [submitFeedback, setSubmitFeedback] = useState('');

	useDidUpdate(() => {
		if (submittedAnswer && submittedAnswer.correct) {
			const correctAnswer = submittedAnswer.correct;
			const selectedAnswer = submittedAnswer.answer;

			const intersection = correctAnswer.filter(value => selectedAnswer.includes(value));
			if (intersection.length > 0) {
				setSubmitFeedback('Your answer is correct');
			} else {
				setSubmitFeedback('Your answer is incorrect');
			}
		} else {
			setSubmitFeedback('');
		}
	}, [submittedAnswer]);

	return [submitFeedback];
}

export default useSubmitFeedback;