import React from 'react';
import PropTypes from 'prop-types';
import { XLg, CheckLg } from 'react-bootstrap-icons';
import { Field } from 'formik';
import { buildClassList } from '../../../common/utils';
import useRadioAnswer from '../../../hooks/useRadioAnswer';

const ChooseOneAnswer = ({ radioGroupName, answerNumber, image, imageAlt, text, submittedAnswer, gateway, ...props }) => {

	const imageAltText = imageAlt || text;

	const {answerId, isCorrectAnswerProvided, isAnswerSelected, isAnswerCorrect, isDisabled, isShowCorrectGlyph, isShowIncorrectGlyph} = 
		useRadioAnswer(radioGroupName, answerNumber, submittedAnswer, gateway);

	const extraImageClass = isCorrectAnswerProvided ?
		(isAnswerSelected ?
			(isAnswerCorrect ?
				"choose-one--answer--icon--correct" : // question is submitted, current answer is selected and is correct
				"choose-one--answer--icon--incorrect") // question is submitted, current answer is selected, but is incorrect
			:
			isAnswerCorrect ? "choose-one--answer--icon--disabled" : "" // question is submitted, current answer is NOT select but is the correct answer
		)
		:
		""; // question was submitted, but no correct answer is available

	return (
		<div className={buildClassList(["choose-one--answer", submittedAnswer ? "submitted" : "", isCorrectAnswerProvided ? (isAnswerCorrect ? "correct" : "incorrect") : ""])}>
			<div className="feedback-container">
				{isShowCorrectGlyph &&
					<CheckLg className="glyph correct" />
				}

				{isShowIncorrectGlyph &&
					<XLg className="glyph incorrect" />
				}
			</div>
			<Field id={answerId}
				type="radio"
				name={radioGroupName}
				disabled={isDisabled}
				value={`${answerNumber}`} 
				aria-label={text} />
			<label htmlFor={answerId}>
				{image &&
					<img className={buildClassList(['choose-one--answer--icon', !text ? 'choose-one--answer--icon--no-text' : '', extraImageClass])} src={process.env.REACT_APP_URL + '/assets/images/activities' + image} alt={imageAltText} />
				}
				{text &&
					<div className="choose-one--answer--text">{text}</div>
				}
			</label>
		</div>
	);
};

ChooseOneAnswer.propTypes = {
	radioGroupName: PropTypes.string,
	answerNumber: PropTypes.number,
	image: PropTypes.string,
	text: PropTypes.string
}

export default ChooseOneAnswer;