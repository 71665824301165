import React from 'react';
import PropTypes from 'prop-types';
import General from './General/General';

const GeneralView = ({ view, ...props }) => {

	return (
		<General title={view.title} text={view.text} />
	);
};

GeneralView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['general']),
		...General.propTypes
	}).isRequired
}

export default GeneralView;