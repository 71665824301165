import React from 'react';
import PropTypes from 'prop-types';
import LabellingDropdown from './LabellingDropdown';

const LabellingItem = ({ questionNumber, image, text, answers, selectAriaLabel, ...props }) => {

	return (
		<div className="labelling--item">
			<div className="labelling--question">
				{image &&
					<img className="labelling--question--image"
						src={process.env.REACT_APP_URL + '/assets/images/activities' + image} alt={text} />
				}

				<div className="labelling--question--text">
					{text}
				</div>
			</div>

			<LabellingDropdown selectAriaLabel={text + ' ' + selectAriaLabel} questionNumber={questionNumber} answers={answers} />
		</div>
	);
};

LabellingItem.propTypes = {
	questionNumber: PropTypes.number,
	image: PropTypes.string,
	text: PropTypes.string,
	answers: PropTypes.arrayOf(PropTypes.string),
	selectAriaLabel: PropTypes.string
}

export default LabellingItem;