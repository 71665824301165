import React from 'react';
import PropTypes from 'prop-types';

// Removed subheader and links because they do not exist in any yaml files
const TextParagraph = ({ text, ...props }) => {

	return (
		<div className="text--paragraph">
			<div className="text--paragraph--text">{text}</div>
		</div>
	);
};

TextParagraph.propTypes = {
	text: PropTypes.string
}

export default TextParagraph;