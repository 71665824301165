import React from 'react';
import PropTypes from 'prop-types';

const TopicProgress = ({ progress, ...props }) => {

	return (
		<>
			<div className="progress topic--progress-bar">
				<div
					className="progress-bar"
					role="progressbar"
					style={{ width: progress + "%" }}
					aria-valuenow={progress}
					aria-valuemin="0"
					aria-valuemax="100"
					aria-labelledby="progress-label">

					{progress + "%"}

				</div>
			</div>
			<div id="progress-label" className="sr-only">{"Current topic is " + progress + "% complete"}</div>
		</>
	);
};

TopicProgress.propTypes = {
	progress: PropTypes.number
}

TopicProgress.defaultProps = {
	progress: 0
}

export default TopicProgress;