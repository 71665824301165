import React from 'react';
import PropTypes from 'prop-types';

const IdentityPlanetQuestion = ({ question, answers, ...props }) => {

	return (
		<div className="profile--modal--identity-planet--quesiton">
			{question}
			<ul>
				{answers.map((answer, i) => <li key={i}>{answer}</li>)}
			</ul>
		</div>
	);
};

IdentityPlanetQuestion.propTypes = {
	question: PropTypes.string,
	answers: PropTypes.array
}

IdentityPlanetQuestion.defaultProps = {
	question: "What are some adjectives you would use to describe yourself?",
	answers: [
		"Determined",
		"Authentic",
		"Adventurous",
		"Organized",
		"Stubborn",
		"Confident"
	]
}

export default IdentityPlanetQuestion;