export const hasSubmittedAnswer = (submittedAnswer) => {
	return submittedAnswer !== null && typeof submittedAnswer !== 'undefined' && typeof submittedAnswer.answer !== 'undefined';
}

export const hasSubmittedAnswers = (submittedAnswers) => {
	return submittedAnswers !== null && typeof submittedAnswers !== 'undefined' && submittedAnswers.length > 0;
}

export const removeAnswer = (array, element) => {
	return array.filter(e => e !== element);
}

export const extractSubmittedAnswerValue = (question, submittedAnswer) => {
	let answers = {};
	if (hasSubmittedAnswer(submittedAnswer)) {
		answers[question.questionNumber.toString()] = submittedAnswer.answer.map((value) => `${value}`);
	}

	return answers;
}

export const extractSubmittedAnswersValues = (questions, submittedAnswers) => {
	let answers = {};
	if (hasSubmittedAnswers(submittedAnswers)) {
		questions.forEach((question) => {
			let number = question.questionNumber.toString();
			if (submittedAnswers[number]) {
				answers[number] = submittedAnswers[number].answer[0].toString();
			} else {
				answers[number] = '';
			}
		});
	}

	return answers;
}

export const answerArraysEqual = (a1, a2) => {
	if (a1.length !== a2.length) {
		return false;
	}

	const intersection = a1.filter(value => a2.includes(value));
	return intersection.length === a1.length;
}