import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment'

const InstructorClassStudentsTable = ({ classId, students, actionsMenu, ...props }) => {

	const unitsCompleted = (student) => {
		return student.trophyCount + " of 9";
	};

	const formatLoginDate = (student) => {
		let date = moment(student.lastLogin);
		if (date.isValid()) {
			return date.format('MM/DD/YYYY');
		} else {
			return "None";
		}
	};

	const formatProgress = (student) => {
		return Math.floor(student.totalProgress) + "%";
	};

	const formatStudentLink = (student) => {
		return (<a href={`/instructor/dashboard/class/${classId}/student/${student.id}`}>{student.displayName}</a>);
	};

	const createRow = (student) => {
		return (
			<tr key={`row-${student.id}`}>
				<td>{formatStudentLink(student)}</td>
				<td>{student.username}</td>
				<td className="centered">{formatLoginDate(student)}</td>
				<td className="centered">{student.loginCount}</td>
				<td className="centered">{unitsCompleted(student)}</td>
				<td className="centered">{formatProgress(student)}</td>
				<td className="centered">{actionsMenu(student)}</td>
			</tr>);
	};

	return (
		<Table responsive bordered>
			<colgroup>
				<col style={{ width: "30%", minWidth: "25%" }} />
				<col style={{ width: "20%", minWidth: "15%" }} />
				<col style={{ width: "10%", minWidth: "10%" }} />
				<col style={{ width: "15%", minWidth: "15%" }} />
				<col style={{ width: "15%", minWidth: "15%" }} />
				<col style={{ width: "15%", minWidth: "10%" }} />
				<col style={{ width: "10%", minWidth: "10%" }} />
			</colgroup>
			<thead>
				<tr>
					<th>Name</th>
					<th>Email</th>
					<th className="centered">Last Login</th>
					<th className="centered">Logins in Last 30 Days</th>
					<th className="centered">Units Completed</th>
					<th className="centered">Progress</th>
					<th className="centered">Actions</th>
				</tr>
			</thead>
			<tbody>
				{students.map(function (student) {
					return createRow(student);
				})}
			</tbody>
		</Table>
	);
};

export default InstructorClassStudentsTable;