import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../common/Loader';
import { Error } from '../common/Error';
import { useAuth } from '../common/ProvideAuth';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import ProfileCharacter from './ProfileCharacter';
import './profile-style.css';
import UnitGrid from './Units/UnitGrid';
import ReportProblem from '../common/ReportProblem';
import useTitle from '../hooks/useTitle';
import { resetKeyboardFocus } from '../common/utils';
import { useEffectOnceWhen } from 'rooks';

const ProfilePage = ( {...props} ) => {
	const auth = useAuth();
	const history = useHistory();
	const [loadingState, setLoadingState] = useState({loading: false, fetched: false});

	useTitle('Vision of You Profile Page');

	useEffectOnceWhen(() => {
		resetKeyboardFocus();
	}, true);

	useEffect(() => {
		if (!loadingState.fetched && !loadingState.loading) {
			setLoadingState({...loadingState, loading: true});
			auth.fetchProfile()
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					auth.viewActivity(null);
					setLoadingState({loading: false, fetched: true});
				});
		}
	}, [auth, loadingState]);

	useEffect(() => {
		if (!loadingState.loading && auth.currentActivity) {
			history.replace("/activity");
		}
	}, [auth.currentActivity, loadingState.loading, history]);

	return (
		<>
			{!auth.user.profile && loadingState.loading &&
				<div className="loader--container">
					<Loader />
				</div>}

			{!auth.user.profile && loadingState.fetched &&
				<div className="profile--container">
					<Error error="profile" />
				</div>
			}

			{auth.user.profile &&
				<div className="profile--container">
					<div id="profile-character" className="profile--left-column" role="region" aria-labelledby="profile-character-heading">
						<ProfileCharacter name={auth.user.profile.character.name} 
							image={auth.user.profile.character.image} 
							group={auth.user.profile.character.group} 
							bio={auth.user.profile.character.bio} 
							goals={auth.user.profile.character.goals} />
					</div>
					<div className="profile--right-column">
						<ProfileHeader profile={auth.user.profile} />
						{auth.user.profile.units &&
							<UnitGrid units={auth.user.profile.units} />
						}
						<ReportProblem />
					</div>
				</div>
			}
		</>
	);
}

export default ProfilePage;