import React from 'react';
import PropTypes from 'prop-types';

const TrophyDisplay = ({ trophies, ...props }) => {

	const trophyImages = trophies.map((trophy, index) =>
		<img key={'trophy-' + index}
			className="profile--header--trophy-display--trophy"
			src={`${process.env.REACT_APP_URL}/assets/images/trophies/${trophy.image}`}
			alt={trophy.name} />
	);

	return (
		<a href="/trophies" className="profile--header--trophy-display dark" aria-label="Open Trophy Selection">
			<div className="profile--header--trophy-display--trophy--container">
				{trophyImages}
			</div>
		</a>
	);
};

TrophyDisplay.propTypes = {
	trophies: PropTypes.arrayOf(PropTypes.shape({
		description: PropTypes.string,
		displayed: PropTypes.bool,
		earned: PropTypes.bool,
		id: PropTypes.string,
		image: PropTypes.string,
		name: PropTypes.string,
		required: PropTypes.bool,
		rule: PropTypes.string
	}))
}

export default TrophyDisplay;