import { useState } from "react";

const useActivityNav = (activity) => {
	const [currentViewNumber, setCurrentViewNumber] = useState(0);
	const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(true);

	const requiresMultiPageForm = () => {
		const quizViews = activity.views.filter(view => view.type === 'quiz');
		return quizViews.length > 1;
	}

	const formPagesCount = () => {
		const quizViews = activity.views.filter(view => view.type === 'quiz');
		return quizViews.length;
	}

	const nextView = () => {
		enableNextButton();
		setCurrentViewNumber(currentViewNumber + 1);
	}

	const previousView = () => {
		enableNextButton();
		setCurrentViewNumber(currentViewNumber - 1);
	}

	const isAtLastView = () => {
		return currentViewNumber + 1 >= activity.views.length
	}

	const isFinalActivity = () => {
		return activity.next === 'final';
	}

	const hasPreviousView = () => {
		return activity.previous || currentViewNumber > 0;
	}

	const hasNextView = () => {
		return (activity.next && !isFinalActivity()) || !isAtLastView();
	}

	const enableNextButton = () => {
		setIsNextButtonEnabled(true);
	}

	const disableNextButton = () => {
		setIsNextButtonEnabled(false);
	}

	return {
		currentViewNumber,
		nextView,
		previousView,
		isNextButtonEnabled,
		enableNextButton,
		disableNextButton,
		hasPreviousView,
		hasNextView,
		isFinalActivity,
		isAtLastView,
		requiresMultiPageForm,
		formPagesCount
		// show,
		// handleClose,
		// handleShow
	}
}

export default useActivityNav;