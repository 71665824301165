import React from 'react';
import PropTypes from 'prop-types';
import Game from './Game/Game';

const GameView = ({ view, ...props }) => {

	return (
		<Game title={view.title} src={view.src} supplemental={view.supplemental} />
	);
};

GameView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['game']),
		...Game.propTypes
	}).isRequired
}

export default GameView;