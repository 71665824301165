import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { buildClassList } from '../../../common/utils';

const OrderingItem = SortableElement(({ id, text, isRequirement, isFlag, ...props }) => {

	let ariaLabel = text;
	if (isFlag) {
		ariaLabel += ': (prioritize later)';
	} else if (isRequirement) {
		ariaLabel += ': (prioritize sooner)';
	}

	return (
		<li id={id} className={buildClassList(["ordering--item flex-fill", isRequirement ? "ordering--item--requirement" : "", isFlag ? "ordering--item--flag" : ""])} role="group" aria-label={ariaLabel} tabIndex={0}>
			<div className="ordering--item--text">{text}</div>
		</li>
	);
});

export default OrderingItem;
