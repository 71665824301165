import React from 'react';
import PropTypes from 'prop-types';
import './quiz-style.css';
import RadioChoiceQuiz from './RadioChoiceQuiz';
import CheckboxQuizQuestion from './CheckboxQuizQuestion';

const Quiz = ({ prompt, questions, answers, inputType, condition, min, limit, storedAnswers, submittedAnswers, activityHelper, isActivityDirty, ...props }) => {

	return (
		<div className="quiz--container">
			{prompt &&
				<div className="quiz--prompt">{prompt}</div>
			}

			{inputType === 'radio' &&
				<RadioChoiceQuiz
					questions={questions}
					answers={answers}
					storedAnswers={storedAnswers}
					submittedAnswers={submittedAnswers}
					handleSubmit={activityHelper.handleSubmit}
					isActivityDirty={isActivityDirty}
					activityHelper={activityHelper} />
			}

			{inputType === 'checkbox' &&
				<CheckboxQuizQuestion
					question={questions[0]}
					answers={answers}
					storedAnswers={storedAnswers}
					submittedAnswers={submittedAnswers}
					handleSubmit={activityHelper.handleSubmit}
					condition={condition}
					answerCountMinimum={min}
					answerCountLimit={limit} 
					isActivityDirty={isActivityDirty}
					activityHelper={activityHelper} />
			}
		</div>
	);
};

Quiz.propTypes = {
	prompt: PropTypes.string,
	questions: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.string,
		text: PropTypes.string
	}))
}

export default Quiz;