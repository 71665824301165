import React, { useState } from 'react';
import { useDidUpdate, useDidMount } from 'rooks';
import { arrayMove } from 'react-sortable-hoc';
import { Button } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import OrderingList from './OrderingList';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';
import { hasSubmittedAnswer, hasSubmittedAnswers } from '../Quiz/utils';
import './ordering-style.css';


const Ordering = ({ question, endpoints, answers, requirements, flags, warningText, activityHelper, isActivityDirty, submittedAnswers, ...props }) => {

	const submittedAnswer = hasSubmittedAnswers(submittedAnswers) ? submittedAnswers[question.questionNumber] : null;

	const buildItems = () => {
		if (submittedAnswer == null) {
			return answers;
		} else {
			let array = [];
			// The leftoverarray thing is used to deal with the situation when someone changes their answers 
			// in a previous activity and the set of answers is not fully accounted for by the submittedAnswer
			let leftoverArray = answers.map(answer => answer.id)
			submittedAnswer.answer.forEach(value => {
				let index = leftoverArray.indexOf(value)
				if (index !== -1) {
					array.push(answers.find(answer => answer.id === value));
					leftoverArray.splice(index, 1);
				}
			});

			answers.forEach(answer => {
				if (leftoverArray.indexOf(answer.id) !== -1) {
					array.push(answer);
				}
			});

			return array;
		}
	}

	const [items, setItems] = useState(buildItems());

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) {
			return;
		}

		setItems(arrayMove(items, oldIndex, newIndex));
		activityHelper.markActivityDirty();
	}

	const checkNextButton = () => {
		if (hasSubmittedAnswer(submittedAnswer) && !isActivityDirty) {
			activityHelper.enableNextButton();
		} else {
			activityHelper.disableNextButton();
		}
	}

	useDidUpdate(() => {
		checkNextButton();
	}, [isActivityDirty, submittedAnswer]);

	useDidMount(() => {
		checkNextButton();
	});

	return (
		<div className="ordering--wrapper">
			<div className="ordering--question--text mb-2">
				{question.text}
			</div>

			<ActivitySupplementalInfo label="Instructions" content="<p>Items can be ordered by navigating to an item you wish to move, activating the spacebar to pick the item up,
								using the up and down arrows to move the item, then activating the spacebar again to drop the item. Ordering can be canceled by activating the escape key.</p>" />

			{/* Note that this form is not functional in the typical sense because the data being submitted is not form inputs. 
			    This is just here to facilitate the validate-submit flow. */}
			<Formik
				initialValues={''}
				validate={(values) => {
					let errors = {};
					let array = items.map((value) => value.id)
					for (let flag of flags) {
						if (array.includes(flag)) {
							for (let i = parseFloat(array.indexOf(flag) + 1); i < array.length; i++) {
								if (requirements.includes(array[i])) {
									errors.warning = warningText;
									return errors;
								}
							}
						}
					}

					return errors;
				}}
				onSubmit={(values) => {
					let array = items.map((value) => value.id)
					let answer = {}
					answer[question.questionNumber.toString()] = array;

					activityHelper.handleSubmit(answer);
				}}
			>
				{({ errors }) => (
					<Form className="d-flex flex-column align-items-center justify-content-center">
						<div className="ordering--container">

							<div className="ordering--end-point ordering--end-point--top">
								{endpoints[0].text}
							</div>

							<OrderingList
								lockAxis="y"
								onSortEnd={onSortEnd}
								requirements={requirements}
								isWarning={errors.warning}
								flags={flags}
								items={items} />

							<div className="ordering--end-point ordering--end-point--bottom">
								{endpoints[1].text}
							</div>
						</div>

						<span aria-live="polite">
							{errors.warning &&
								<div className="ordering--warning">{errors.warning}</div>
							}
						</span>

						<Button className="mt-2" variant="light" type="submit" disabled={submittedAnswer && !isActivityDirty}>Submit</Button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default Ordering;