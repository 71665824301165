import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDidMount } from 'rooks';
import { fetchStudentProgress } from '../../api/instructors';
import { Loader } from '../../common/Loader';
import { Table } from 'react-bootstrap';
import './instructor-classes.css';
import { useAuth } from '../../common/ProvideAuth';

const StudentProgressPage = ({ ...props }) => {
	const auth = useAuth();
	const { classId, studentId } = useParams();
	const [isFetching, setIsFetching] = useState(true);

	const [voyClass, setVoyClass] = useState({ id: 0, active: 0, activeStudents: [], inactiveStudents: [], classCode: "", instructorId: 0, locationId: 0, name: "" });
	const [student, setStudent] = useState({ id: null, displayName: "", username: "", units: [] });

	useDidMount(() => {
		loadStudentProgress();
	});

	const formatStatusLabel = (unit) => {
		let label = "In Progress";
		if (unit.progress === 100) {
			label = "Completed";
		} else if (unit.progress === 0) {
			label = "Not Started";
		}

		return label;
	};

	const formatProgressLabel = (unit) => {
		return `${unit.progress}%`;
	};

	const createRow = (unit) => {
		return (
			<tr key={`row-${unit.id}`}>
				<td className="centered">{unit.id}</td>
				<td>{unit.title}</td>
				<td>{formatStatusLabel(unit)}</td>
				<td className="centered">{formatProgressLabel(unit)}</td>
			</tr>);
	};

	const loadStudentProgress = () => {
		setIsFetching(true);
		fetchStudentProgress(auth.user.authToken, studentId, classId).then(function (response) {
			switch (response.data.code) {
				case 200:
					setIsFetching(false);
					setVoyClass(response.data.progress.voyClass);
					setStudent(response.data.progress.student);
					break;

				default:
					auth.sessionTimeout();
			}
		});
	};

	return (
		<>
			{isFetching &&
				<div className="loader--container">
					<Loader />
				</div>
			}

			{!isFetching &&
				<div className="instructor-classes">
					<div className="instructor-classes--breadcrumb">
						<Link to={`/instructor/dashboard/class/${classId}/students`}>&lt; {voyClass ? `Back to ${voyClass.name} Students` : "Back to Students"}</Link>
					</div>

					{student.id &&
						<div>
							<div className="instructor-classes--header">
								<h2>{`${student.displayName} Progress`}</h2>
							</div>

							<div className="instructor-classes--table">
								<Table responsive bordered>
									<colgroup>
										<col style={{ width: "10%", minWidth: "10%" }} />
										<col style={{ width: "60%", minWidth: "60%" }} />
										<col style={{ width: "20%", minWidth: "20%" }} />
										<col style={{ width: "10%", minWidth: "10%" }} />
									</colgroup>
									<thead>
										<tr>
											<th className="centered">Unit #</th>
											<th>Unit Name</th>
											<th>Status</th>
											<th className="centered">Progress</th>
										</tr>
									</thead>
									<tbody>
										{student.units.map(function (unit) {
											return createRow(unit);
										})}
									</tbody>
								</Table>
							</div>
						</div>
					}

					{!student.id &&
						<div className="error form-error">Could not locate the selected student.</div>
					}
				</div>
			}
		</>
	);
};

export default StudentProgressPage;