import React from 'react';
import './login-style.css';

const PasswordResetError = () => {
	return (
		<div className="login--form--container">
			<h2>Reset Password Error</h2>
			<div className="forgot-password--confirmation">
				<p>There was an error trying to reset your password. Please return to the <a href="/password">Forgot Password page</a> to generate a new link.</p>
			</div>
		</div>
	);
};

export default PasswordResetError;