export const buildClassList = (classes) => {
	return classes.filter(e => !!e).join(' ')
}

export const formatTimeRemaining = (durationInMs) => {
	const d = Number(durationInMs);
	const h = Math.floor(d / 3600);
	const m = Math.floor(d % 3600 / 60);
	const s = Math.floor(d % 3600 % 60);

	const hDisplay = h > 0 ? h + ":" : "";
	const mDisplay = m > 0 ? (m + '').padStart(2, '0') + ":" : "00:";
	const sDisplay = s > 0 ? (s + '').padStart(2, '0') : "00";
	return hDisplay + mDisplay + sDisplay;
}

export const resetKeyboardFocus = () => {
	window.scrollTo(0,0);
	document.documentElement.tabIndex = 0;
	document.documentElement.focus();
}

export const arraysEqual = (a1, a2) => {
	if (a1.length !== a2.length) {
		return false;
	}

	const intersection = a1.filter(value => a2.includes(value));
	return intersection.length === a1.length;
}