import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ src, ...props }) => {

	return (
		<div className="video--container">
			<iframe title="Vision of You Video" src={src} width="1000" height="560" frameBorder="0" allowFullScreen></iframe>
		</div>
	);
};

Video.propTypes = {
	src: PropTypes.string.isRequired
}

export default Video;