import React from 'react';
import PropTypes from 'prop-types';
import Graphic from './Graphic/Graphic';

const GraphicView = ({ view, ...props }) => {

	return (
		<Graphic image={view.image} style={view.style} imageAlt={view.imageAlt} supplemental={view.supplemental} />
	);
};

GraphicView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['graphic']),
		...Graphic.propTypes
	}).isRequired
}

export default GraphicView;