import React from 'react';
import PropTypes from 'prop-types';
import './Text/text-style.css';

const None = ({ view, ...props }) => {

	return (
		<div className="text--container">
			<div className="text--header">Not Yet Implemented</div>
			<div className="text--paragraph">
				View state:
				<br></br>
				<pre>{JSON.stringify(view)}</pre>
			</div>
		</div>
	);
};

None.propTypes = {
	view: PropTypes.object
}

None.defaultProps = {
	view: {
		text: "Foooo",
		stuff: "Garbage"
	}
}

export default None;