import React, { useState } from 'react';
import { useDidUpdate } from "rooks";
import { Button } from 'react-bootstrap';
import './spinning-wheel-style.css';
import SpinningWheel from './SpinningWheel';
import SpinningWheelTerm from './SpinningWheelTerm';
import useSpinningWheel from './useSpinningWheel';

const SpinningWheelDisplay = ({ prompt, foreground, background, rotations, questions, ...props }) => {

	const { currentIndex, setCurrentIndex, isWheelSpinning, setIsWheelSpinning } = useSpinningWheel();
	const [currentQuestion, setCurrentQuestion] = useState(questions[0]);

	const handleSpin = () => {
		if (isWheelSpinning) {
			return;
		}

		var nextIndex = currentIndex + 1;
		if (nextIndex >= rotations.length) {
			nextIndex = 1;
		}
		setCurrentIndex(nextIndex);
	}

	useDidUpdate(() => {
		setIsWheelSpinning(true);
		setTimeout(function () {
			setCurrentQuestion(questions[currentIndex]);
			setIsWheelSpinning(false);
		}, 2200);
	}, [currentIndex, questions, rotations])

	return (
		<div className="spinning-wheel--container">
			<SpinningWheelTerm prompt={prompt} term={currentQuestion.term} definition={currentQuestion.definition} isWheelSpinning={isWheelSpinning} />
			
			<SpinningWheel foreground={foreground} background={background} rotations={rotations} rotationIndex={currentIndex} />

			<div className="spinning-wheel--button--container">
				<Button className="spinning-wheel--button" variant="danger" size="lg" onClick={handleSpin}>Spin</Button>
			</div>
		</div>
	);
};

export default SpinningWheelDisplay;