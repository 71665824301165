import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-bootstrap-icons';

const TopicHeader = ({ unitNumber, unitTitle, topicTitle, handleClose, ...props }) => {
	return (
		<div className='topic--header dark' role="region" aria-label="Topic Header">
			<h2 className="topic--unit--title">{unitTitle}</h2>
			<h3 className="topic--topic--title">{topicTitle}</h3>
			<div className="topic--close-button--container" onClick={handleClose}>
				<button className="btn btn-link" role="link" onClick={handleClose}>
					<X className="topic--close-button--glyph" aria-label="Close Activity" />
				</button>
			</div>
		</div>
	);
};

TopicHeader.propTypes = {
	unitNumber: PropTypes.number,
	unitTitle: PropTypes.string,
	topicTitle: PropTypes.string,
	handleClose: PropTypes.func
}

TopicHeader.defaultProps = {
	unitNumber: 1,
	unitTitle: "Example Unit",
	topicTitle: "Example Topic",
	handleClose: function () {
	}
}

export default TopicHeader;