import React from 'react';
import { DEFAULT_TYPES, DEFAULT_PROPS } from './default-character';

const ProfileCharacterModalBody = ({ name, image, bio, goals, ...props }) => {

	return (
		<div className="profile--character--modal--container">
			<img className="profile--character--modal--image"
				src={process.env.REACT_APP_URL + '/assets/images/characters' + image}
				alt={name} />

			<div className="profile--character--modal--bio--container">
				<div className="profile--character--modal--bio-name">
					{name}
				</div>
				<div className="profile--character--modal--bio--text">
					{bio}
				</div>
				<div className="profile--character--modal--bio--goals">
					Goals:
					<ul className="profile--character--modal--bio--goals--list">
						{goals ?
							goals.map((goal, index) =>
								<li key={index}>{goal.text} {goal.pointsRequired}</li>) :
							false
						}
					</ul>
				</div>
			</div>
		</div>
	);
};

ProfileCharacterModalBody.propTypes = DEFAULT_TYPES;
ProfileCharacterModalBody.defaultProps = DEFAULT_PROPS;

export default ProfileCharacterModalBody;