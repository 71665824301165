import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDidUpdate, useDidMount } from 'rooks';

const EnterTextBox = ({ questionNumber, submittedText, wordMinCount, profanityFilter, filterList, handleSubmit, buttonHelper, ...props }) => {

	const validateLength = (text) => {
		const wordArray = text.replace(/\s{2,}/g, " ").split(' ').filter(entry => entry);
		if (wordMinCount > 0) {
			const remainingWordCount = wordMinCount - wordArray.length;
			if (remainingWordCount > 0) {
				return `Please enter at least ${remainingWordCount} more words`;
			}
		}

		return '';
	};

	const validateFilter = (text) => {
		const scrubbed = text.toLowerCase().replace(/['.,/#!$%^&*;:{}=\-_`~()]/g, " ").replace(/\s{2,}/g, " ").split(' ');

		if (profanityFilter) {
			for (let word of profanityFilter) {
				let filterArray = word.split(' '); // for multi-word entries in the profanity list
				if (scrubbed.includes(filterArray[0].toLowerCase())) {
					if (filterArray[1]) {
						// the below will check if two-word entries in the filter appear together in the input text
						if (scrubbed[scrubbed.indexOf(filterArray[0].toLowerCase()) + 1] === filterArray[1].toLowerCase()) {
							return `You have used a word that is derogatory or has historically been used as a slur against a group of people.
								Sometimes people of the identity who the word has been used against will reclaim the word by identifying with it or using it
								themselves but that is for the individual of that identity to decide. Please use other language to describe the person in the picture.`;
						}
					} else {
						return `You have used a word that is derogatory or has historically been used as a slur against a group of people.
							Sometimes people of the identity who the word has been used against will reclaim the word by identifying with it or using it
							themselves but that is for the individual of that identity to decide. Please use other language to describe the person in the picture.`;
					}
				}
			}
		}

		if (filterList) {
			for (let word of filterList) {
				if (scrubbed.includes(word.toLowerCase())) {
					return 'Try not to use any gender pronouns like "he" or "she"';
				}
			}
		}

		return '';
	};

	const checkNextButton = () => {
		if (submittedText) {
			buttonHelper.enableNextButton();
		} else {
			buttonHelper.disableNextButton();
		}
	};

	useDidMount(() => {
		checkNextButton();
	});

	useDidUpdate(() => {
		checkNextButton();
	}, [submittedText]);

	const buildInitialValue = () => {
		let values = {};
		values[questionNumber] = submittedText ? submittedText : '';
		return values;
	}

	return (
		<div className="enter-text--box--container">
			<Formik
				initialValues={buildInitialValue()}
				onSubmit={(values) => {
					handleSubmit(values);
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validate={(values) => {
					const text = values[questionNumber];
					let error = validateFilter(text);
					if (!error) {
						error = validateLength(text);
					}

					let errors = {};
					if (error) {
						errors[questionNumber] = error;
					}
					return errors;
				}}
			>
				<Form>
					<Field
						aria-labelledby="enter-text-label"
						component="textarea"
						className="enter-text--box"
						name={questionNumber} />

					<span className="error form-error" aria-live="polite">
						<ErrorMessage name={questionNumber} />
					</span>

					<div className="d-flex justify-content-end">
						<Button className="float-end" variant="light" type="submit">Submit</Button>
					</div>
				</Form>
			</Formik>
		</div>
	);
};

EnterTextBox.propTypes = {
	submittedText: PropTypes.string,
	wordMinCount: PropTypes.number,
	profanityFilter: PropTypes.arrayOf(PropTypes.string),
	filterList: PropTypes.arrayOf(PropTypes.string),
	handleSubmit: PropTypes.func
}

export default EnterTextBox;