export const constraints = {
    email: {
        presence: {
            allowEmpty: false,
            message: "^Only accounts with a valid email address may reset their own password."
        },
        email: {
            message: "^Only accounts with a valid email address may reset their own password."
        }
    }
}

export default constraints;