import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';

const InteractiveGraphicOverlay = ({ name, coords, overlay, ...props }) => {

	return (
		<OverlayTrigger
			trigger={['hover', 'focus']}
			placement="bottom"
			overlay={overlay}
		>

			<div
				tabIndex={0}
				className="interactive-graphic--hover-area"
				style={{
					left: coords[0],
					top: coords[1],
					width: coords[2] - coords[0],
					height: coords[3] - coords[1]
				}}
			/>

		</OverlayTrigger>
	);
};

InteractiveGraphicOverlay.propTypes = {
	name: PropTypes.string,
	coords: PropTypes.arrayOf(PropTypes.number),
	overlay: PropTypes.element
}

export default InteractiveGraphicOverlay;