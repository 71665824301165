import React from 'react';
import PropTypes from 'prop-types';
import { selectCharacter } from '../../api/character';
import InteractiveGraphic from './InteractiveGraphic/InteractiveGraphic';
import { useAuth } from '../../common/ProvideAuth';

const InteractiveGraphicView = ({ view, submittedAnswer, activityHelper, ...props }) => {

	const auth = useAuth();

	// The only selection subtype view is for choosing a character
	if (submittedAnswer == null && view.subtype === 'selection') {
		if (auth.user.profile.character) {
			submittedAnswer = auth.user.profile.character.name;
		}
	}

	const handleRedirect = (activityId) => {
		activityHelper.gotoActivity(activityId);
	}

	const handleSelection = (selection) => {
		return selectCharacter(auth.user.authToken, selection);
	}

	return (
		<InteractiveGraphic prompt={view.prompt} image={view.image} imageAlt={view.imageAlt} subtype={view.subtype} hotspots={view.hotspots} 
			allowHighlighting={view.allowHighlighting} supplemental={view.supplemental}
			handleRedirect={handleRedirect} handleSelection={handleSelection} submittedSelection={submittedAnswer} activityHelper={activityHelper}/>
	);
};

InteractiveGraphicView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['interactiveGraphic']),
		...InteractiveGraphic.propTypes
	}).isRequired,
	submittedAnswer: PropTypes.shape({
		answer: PropTypes.array,
		evaluated: PropTypes.bool
	})
}

export default InteractiveGraphicView;