import React from 'react';
import PropTypes from 'prop-types';

const SpinningWheel = ({ foreground, background, rotations, rotationIndex, ...props }) => {

	const style = { transform: 'rotate(' + rotations[rotationIndex] + 'deg)' };

	return (
		<div className="spinning-wheel">
			<img
				className="spinning-wheel--image--foreground"
				src={process.env.REACT_APP_URL + '/assets/images/activities' + foreground}
				alt="" />

			<img
				className="spinning-wheel--image--background"
				src={process.env.REACT_APP_URL + '/assets/images/activities' + background}
				style={style}
				alt="" />
		</div>
	);
};

SpinningWheel.propTypes = {
	background: PropTypes.string.isRequired,
	foreground: PropTypes.string.isRequired,
	rotations: PropTypes.arrayOf(PropTypes.number).isRequired,
	rotationIndex: PropTypes.number.isRequired
}

export default SpinningWheel;