import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text/Text';

const TextView = ({ view, ...props }) => {
	return (
		<Text header={view.header} warning={view.warning} paragraphs={view.paragraphs} />
	);
};

TextView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['text']),
		...Text.propTypes
	}).isRequired
}

export default TextView;