import React, { useState } from 'react';
import ActivityNav from './ActivityNav';
import { Button, Modal } from 'react-bootstrap';
import { resetKeyboardFocus } from '../common/utils';
import { useDidUpdate, useEffectOnceWhen, useSessionstorageState } from 'rooks';
import useActivityNav from '../hooks/useActivityNav';
import useView from '../hooks/useView';
import GatewayQuizHeader from './View/GatewayQuiz/GatewayQuizHeader';
import useTitle from '../hooks/useTitle';
import ActivityFeedback from './ActivityFeedback';

const ActivityContainer = ({ unit, topic, activity, closeActivity, gotoActivity, submitActivityAnswer, ...props }) => {

	const navHelper = useActivityNav(activity);
	const currentView = activity.views[navHelper.currentViewNumber];
	const [storedAnswers, setStoredAnswers, removeStoredAnswers] = useSessionstorageState(activity.id, null);
	const [isActivityDirty, setIsActivityDirty] = useState(false);

	const ViewType = useView(currentView);
	const gateway = activity.gateway;
	let activityTitle = activity.title;
	let subtitle = (currentView && currentView.subtitle ? `${currentView.subtitle}` : "");
	const pagination = (navHelper.requiresMultiPageForm() ? `Page ${navHelper.currentViewNumber + 1} of ${navHelper.formPagesCount()}` : "");
	if (subtitle && pagination) {
		subtitle = `${subtitle} - ${pagination}`;
	} else if (pagination) {
		activityTitle = `${activityTitle} - ${pagination}`;
	}
	const viewTitle = activityTitle + (subtitle ? ` - ${subtitle}` : "");
	const [feedbackModal, setFeedbackModal] = useState({ feedback: null, modalOpen: false });

	useTitle(`Vision of You: ${unit}: ${topic}: ${viewTitle}`);

	const markActivityDirty = () => {
		setIsActivityDirty(true);
	}

	const initializeStoredAnswers = () => {
		let answers = [];
		for (let i = 0; i < activity.questionCount; i++) {
			if (activity.submittedAnswers && activity.submittedAnswers[i]) {
				answers.push(activity.submittedAnswers[i]);
			} else {
				break;
			}
		}

		if (answers.length > 0) {
			setStoredAnswers(answers);
		} else {
			removeStoredAnswers();
		}
	}

	// This decides what happens when hitting the ESC key on a feedback modal
	const specializedClose = () => {
		const feedback = feedbackModal.feedback;
		if (feedback.allowTryAgain && !feedback.allowContinue) {
			modalClose();
		} else {
			handleNext();
		}
	}

	const modalClose = () => {
		setFeedbackModal({ feedback: null, modalOpen: false });
	};

	const modalOpen = (feedback) => {
		setFeedbackModal({ feedback: feedback, modalOpen: true });
	};

	const handlePrevious = () => {
		if (navHelper.currentViewNumber > 0) {
			navHelper.previousView();
			resetKeyboardFocus();
		} else if (activity.previous) {
			gotoActivity(activity.previous);
		} else {
			console.error('It should not be possible to get here!!');
		}
	}

	const handleNext = () => {
		if (!navHelper.isAtLastView()) {
			removeStoredAnswers();
			navHelper.nextView();
			resetKeyboardFocus();
		} else if (!navHelper.isFinalActivity()) {
			gotoActivity(activity.next);
		} else {
			removeStoredAnswers();
			closeActivity();
		}
	};

	const isGateway = () => {
		return gateway;
	}

	const makeArrayIfNeeded = (answer) => {
		if (Array.isArray(answer)) {
			return answer;
		} else {
			return [answer];
		}
	};

	const pushStoredAnswers = (answers) => {
		const copy = storedAnswers ? [...storedAnswers] : [];
		for (const [questionNumber, answer] of Object.entries(answers)) {
			copy[questionNumber] = { answer: makeArrayIfNeeded(answer) };
		}

		setStoredAnswers(copy);
		return copy;
	};

	/*
	 * This method must always return a Promise, because downstream components may
	 * be coded to take additional action when the Promise resolves
	 */
	const handleSubmit = (answer, sendToServer = true) => {
		let answers = pushStoredAnswers(answer);
		
		if (sendToServer) {
			return submitActivityAnswer(answers)
				.then((feedback) => {
					if (feedback) {
						modalOpen(feedback);
					}
					setIsActivityDirty(false);
					removeStoredAnswers();
				});
		} else {
			return new Promise((resolve, reject) => { resolve(); });
		}
	};

	useDidUpdate(() => {
		if (feedbackModal && feedbackModal.feedback && !feedbackModal.feedback.allowContinue) {
			navHelper.disableNextButton();
		}
	}, [feedbackModal]);

	useEffectOnceWhen(() => {
		initializeStoredAnswers();
		resetKeyboardFocus();
	}, true);

	const buildViewKey = () => {
		let key = `${activity.id}_${navHelper.currentViewNumber}`;
		if (gateway) {
			const answeredCount = gateway.answeredQuestions ? gateway.answeredQuestions.length : 0;
			key += `_${answeredCount}`;
		}

		return key;
	}

	return (
		<>
			<div className="topic--activity--container" role="region" aria-label="Activity Content">
				<div id="main-content" className="topic--activity--content dark">
					<h4 className="topic--activity--title text-center">{activityTitle}</h4>
					{subtitle &&
						<h5 className="topic--topic--title text-center">{subtitle}</h5>
					}
					{gateway &&
						<GatewayQuizHeader goal={gateway.goal} answeredQuestions={gateway.answeredQuestions} submittedAnswers={activity.submittedAnswers} />
					}
					{/* Adding a key below helps React to figure out that the entire component needs re-rendering when we navigate. Without it, I was getting unexpected behavior with stale values. */}
					<ViewType key={buildViewKey()} view={currentView} activityHelper={{ ...navHelper, handleSubmit, gotoActivity, pushStoredAnswers, handleNext, markActivityDirty, isGateway }} storedAnswers={storedAnswers} submittedAnswers={activity.submittedAnswers} isActivityDirty={isActivityDirty} />
				</div>
				<div className="topic--activity--nav--container">
					<ActivityNav navHelper={{ handlePrevious, handleNext, ...navHelper }} initialWaitTime={activity.wait ? (activity.wait / 1000) : 0} />
				</div>
			</div>

			<Modal className="topic--activity--feedback" show={feedbackModal.modalOpen} onHide={specializedClose}>
				<Modal.Header>
					<Modal.Title>Feedback</Modal.Title>
				</Modal.Header>

				<Modal.Body className="topic--activity--feedback--modal">
					<ActivityFeedback feedback={feedbackModal.feedback} />
				</Modal.Body>

				<Modal.Footer>
					{feedbackModal.feedback && feedbackModal.feedback.allowTryAgain &&
						<Button variant="primary" onClick={modalClose}>Try Another Answer</Button>
					}

					{feedbackModal.feedback && feedbackModal.feedback.allowContinue ?
						feedbackModal.feedback && feedbackModal.feedback.allowTryAgain ?
							<Button variant="primary" onClick={handleNext}>Keep Answer and Continue</Button> :
							<Button variant="primary" onClick={handleNext}>Next</Button> : false
					}

					{feedbackModal.feedback && !feedbackModal.feedback.allowContinue && !feedbackModal.feedback.allowTryAgain &&
						<Button variant="primary" onClick={handleNext}>Next</Button>
					}
				</Modal.Footer>
			</Modal>

		</>
	);
};

export default ActivityContainer;