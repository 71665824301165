import React from 'react';
import PropTypes from 'prop-types';
import { XCircle, CheckCircle, QuestionCircle } from 'react-bootstrap-icons';

const GatewayQuizAnswerMarker = ({ index, isAnswered, isCorrect, ...props }) => {

	var labelPrefix = "Current Question";
	if (index) {
		labelPrefix = `Question ${index}`;
	}

	return (
		<div className="topic--activity--gateway-quiz--marker">
			{!isAnswered &&
				<QuestionCircle className="topic--activity--gateway-quiz--glyph" aria-label={`${labelPrefix}: Unanswered`} />
			}

			{isAnswered && isCorrect &&
				<CheckCircle className="topic--activity--gateway-quiz--glyph correct" aria-label={`${labelPrefix}: Correct`} />
			}

			{isAnswered && !isCorrect &&
				<XCircle className="topic--activity--gateway-quiz--glyph incorrect" aria-label={`${labelPrefix}: Incorrect`} />
			}
		</div>
	);
};

GatewayQuizAnswerMarker.propTypes = {
	isAnswered: PropTypes.bool,
	isCorrect: PropTypes.bool
}

GatewayQuizAnswerMarker.defaultProps = {
	isAnswered: false
}

export default GatewayQuizAnswerMarker;