import React from 'react';
import PropTypes from 'prop-types';
import './labelling-style.css';
import LabellingItem from './LabellingItem';

const Labelling = ({ prompt, categories, questions, answers, ...props }) => {

	return (
		<div className="labelling--container">
			<div className="labelling--prompt">
				{prompt}
			</div>
			<div className="labelling--categories">
				{categories.map((category, index) => <div key={index} className="labelling--category">{category}</div>)}
			</div>
			<div className="labelling--items">
				{
					questions.map((question, index) =>
						<LabellingItem key={index}
							questionNumber={index}
							image={question.image}
							text={question.text}
							answers={answers} 
							selectAriaLabel={categories[categories.length - 1]} />
					)
				}
			</div>
		</div>
	);
};

Labelling.propTypes = {
	prompt: PropTypes.string,
	categories: PropTypes.arrayOf(PropTypes.string),
	questions: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.string,
		text: PropTypes.string
	})),
	answers: PropTypes.arrayOf(PropTypes.string)
}

export default Labelling;