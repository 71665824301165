import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import useModal from '../hooks/useModal';

const ActivitySupplementalInfo = ({ label, content, ...props }) => {

	const { show, handleClose, handleShow } = useModal();

	return (
		<>
			<div className="activity-supplemental">
				<button type="button" aria-label={`View ${label}`} onClick={handleShow}><InfoCircle style={{ color: "#FFF" }} /></button>
			</div>

			<Modal className="activity-supplemental-modal" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>{label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: content }}></div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

ActivitySupplementalInfo.propTypes = {
	label: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
}

export default ActivitySupplementalInfo;