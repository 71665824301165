import React from 'react';
import PropTypes from 'prop-types';
import './graphic-style.css';
import { buildClassList } from '../../../common/utils';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';

const Graphic = ({ image, imageAlt, supplemental, style, ...props }) => {
	// I removed the header and text stuff here from the original, because I could not find any instances
	// of a Graphic view that actually has either of these values set.
	return (
		<div className="graphic--container">
			<img
				className={buildClassList(['graphic--image', 'graphic--' + style])}
				src={process.env.REACT_APP_URL + '/assets/images/activities' + image}
				alt={imageAlt} />

			{supplemental &&
				<ActivitySupplementalInfo label={supplemental.label} content={supplemental.content} />
			}
		</div>
	);
};

Graphic.propTypes = {
	image: PropTypes.string.isRequired,
	style: PropTypes.string,
	imageAlt: PropTypes.string,
	supplemental: PropTypes.shape({
		label: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired
	})
}

export default Graphic;