import React from 'react';
import PropTypes from 'prop-types';
import Video from './Video/Video';

const VideoView = ({ view, ...props }) => {
	return (
		<Video src={view.src} />
	);
};

VideoView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['video']),
		...Video.propTypes
	}).isRequired
}

export default VideoView;