import React from 'react';
import PropTypes from 'prop-types';
import EnterTextQuestion from './EnterTextQuestion';
import EnterTextBox from './EnterTextBox';
import './enter-text-style.css';

const EnterText = ({ question, submittedText, wordMinCount, handleSubmit, profanityFilter, filterList, buttonHelper, ...props }) => {

	return (
		<div className="enter-text--container">
			<EnterTextQuestion text={question.text} image={question.image} imageAlt={question.imageAlt} />
			<EnterTextBox questionNumber={question.questionNumber.toString()} submittedText={submittedText} wordMinCount={wordMinCount} handleSubmit={handleSubmit} profanityFilter={profanityFilter} filterList={filterList} buttonHelper={buttonHelper} />
		</div>
	);
};

EnterText.propTypes = {
	...EnterTextBox.propTypes,
	question: PropTypes.shape(EnterTextQuestion.propTypes)
}

export default EnterText;