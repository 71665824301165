import React from 'react';
import PropTypes from 'prop-types';
import None from './View/None';

const ActivityFeedback = ({ feedback, ...props }) => {

	if (!feedback) {
		return <None />
	} else {
		return (
			<>
				{feedback.image &&
					<img className="topic--activity--feedback--image" src={`${process.env.REACT_APP_URL}/assets/images${feedback.image}`} alt={`${feedback.imageAlt}`} />
				}
	
				{feedback.text &&
					<div className="topic--activity--feedback--text">{feedback.text}</div>
				}
	
				{feedback.list &&
					<ul className="topic--activity--feedback--list">
						{feedback.list[0]}
						{feedback.list.slice(1).map((item, index) => <li key={index}>{item}</li>)}
					</ul>
				}
			</>
		);
		}
};

ActivityFeedback.propTypes = {
	feedback: PropTypes.shape({
		image: PropTypes.string,
		imageAlt: PropTypes.string,
		text: PropTypes.string,
		list: PropTypes.arrayOf(PropTypes.string)
	})
}

export default ActivityFeedback;