import React from 'react';
import PropTypes from 'prop-types';

const IdentityPlanetQuestion = ({ text, answers, ...props }) => {

	return (
		<div className="identity-planet--question">
			{text}
			<ul>
				{answers.map((answer, index) => <li key={index}>{answer}</li>)}
			</ul>
		</div>
	);
};

IdentityPlanetQuestion.propTypes = {
	text: PropTypes.string,
	answers: PropTypes.arrayOf(PropTypes.string)
}

export default IdentityPlanetQuestion;