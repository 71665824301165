import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import { Loader } from '../../common/Loader';
import { useParams } from 'react-router';
import { useDidMount } from 'rooks';
import InstructorClassStudentsTable from './InstructorClassStudentsTable';
import { fetchInstructorClass, toggleStudentStatus, updateStudent, updateStudentPassword } from '../../api/instructors';

import './instructor-classes.css';
import { useAuth } from '../../common/ProvideAuth';
import useModal from '../../hooks/useModal';
import { buildClassList } from '../../common/utils';
import { validate } from 'validate.js';
import studentConstraints from './student-constraints';
import passwordConstraints from './student-password-constraints';

const InstructorClassStudentsPage = ({ ...props }) => {

	const auth = useAuth();
	const { show: passwordModalOpen, handleClose: closePasswordModal, handleShow: showPasswordModal } = useModal();
	const [passwordModalData, setPasswordModalData] = useState({ studentId: null, studentName: '', studentEmail: '', password: '', passwordConfirm: '' });
	const { show: editdModalOpen, handleClose: closeEditModal, handleShow: showEditModal } = useModal();
	const [editModalData, setEditModalData] = useState({ studentId: null, studentName: '', studentEmail: '' });
	const { classId } = useParams();
	const [isFetching, setIsFetching] = useState(true);
	const [voyClass, setVoyClass] = useState({ id: 0, active: 0, activeStudents: [], inactiveStudents: [], classCode: "", instructorId: 0, locationId: 0, name: "" })
	const [formError, setFormError] = useState('');

	useDidMount(() => {
		loadInstructorClass();
	});

	const loadInstructorClass = () => {
		setIsFetching(true);
		fetchInstructorClass(auth.user.authToken, classId).then(function (response) {
			switch (response.data.code) {
				case 200:
					setIsFetching(false);
					setVoyClass(response.data.voyClass);
					break;

				default:
					auth.sessionTimeout();
			}
		});
	};

	const cancelEditStudent = () => {
		clearEditModalData();
		closeEditModal();
		setFormError('');
		loadInstructorClass();
	};

	const editStudent = (student) => {
		let username = student.username;
		if (formError) {
			username = editModalData.studentEmail;
		}
		setEditModalData({ studentId: student.id, studentName: student.displayName, studentEmail: username });
		showEditModal();
	};

	const saveEditedStudent = (values) => {
		const student = { ...editModalData, studentName: values.studentName, studentEmail: values.studentEmail };
		setEditModalData(student);
		updateStudent(auth.user.authToken, student.studentId, student.studentName, student.studentEmail).then(function (response) {
			switch (response.data.code) {
				case 200:
					cancelEditStudent();
					break;

				case 3403:
					setFormError(`The provided email ${student.studentEmail} is already in use.`);
					break;

				default:
					auth.sessionTimeout();
			}
		});
	};

	const clearEditModalData = () => {
		setEditModalData({ studentId: null, studentName: '', studentEmail: '' });
	};

	const openPasswordModal = (student) => {
		setPasswordModalData({...passwordModalData, studentId: student.id, studentName: student.name, studentEmail: student.username});
		showPasswordModal();
	};

	const cancelChangePassword = () => {
		clearPasswordModalData();
		closePasswordModal();
		setFormError('');
	};

	const clearPasswordModalData = () => {
		setPasswordModalData({ studentId: null, studentName: '', studentEmail: '', password: '', passwordConfirm: '' });
	};

	const savePasswordChange = (values) => {
		updateStudentPassword(auth.user.authToken, passwordModalData.studentId, values.password, values.passwordConfirm).then(function (response) {
			switch (response.data.code) {
				case 200:
					cancelChangePassword();
					break;

				default:
					auth.sessionTimeout();
			}
		});
	}

	const toggleStatus = (student) => {
		toggleStudentStatus(auth.user.authToken, student.id, classId).then(function (response) {
			switch (response.data.code) {
				case 200:
					loadInstructorClass();
					break;

				default:
					auth.sessionTimeout();
			}
		});
	};

	const createActionsMenu = (row) => {
		let student = { id: row.id, displayName: row.displayName, username: row.username, isDisabled: row.isDisabled };
		let statusString = row.isDisabled === 0 ? 'Deactivate' : 'Activate';
		return (
			<DropdownButton id={"instructor-classes--actions-" + row.id} size="sm" title="Actions" variant="outline-secondary">
				<Dropdown.Item as="button" onClick={() => editStudent(student)}>Edit</Dropdown.Item>
				<Dropdown.Item as="button" onClick={() => openPasswordModal(student)}>Change Password</Dropdown.Item>
				<Dropdown.Item as="button" onClick={() => toggleStatus(student)}>{statusString}</Dropdown.Item>
			</DropdownButton>
		);
	};

	return (
		<>
			{isFetching &&
				<div className="loader--container">
					<Loader />
				</div>
			}

			{!isFetching &&
				<div className="instructor-classes">
					<div className="instructor-classes--breadcrumb">
						<a href="/instructor/dashboard/classes">&lt; Back to Classes</a>
					</div>
					<div className="instructor-classes--header">
						<h2>{`${voyClass.name} Students`}</h2>
					</div>

					<div className="instructor-classes--table">
						<h3>Active</h3>
						<InstructorClassStudentsTable classId={voyClass.id} students={voyClass.activeStudents} actionsMenu={createActionsMenu} />
					</div>

					<div className="instructor-classes--table">
						<h3>Inactive</h3>
						<InstructorClassStudentsTable classId={voyClass.id} students={voyClass.inactiveStudents} actionsMenu={createActionsMenu} />
					</div>
				</div>
			}

			<Modal show={editdModalOpen} onHide={cancelEditStudent} className="instructor-classes--edit-student-modal">
				<Modal.Header closeButton={true} onHide={cancelEditStudent}>
					<Modal.Title>Edit Student</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={editModalData}
						validate={(values) => {
							let errors = {};
							let validationResult = validate(values, studentConstraints)

							for (const [field] of Object.entries(values)) {
								if (validationResult && validationResult[field]) {
									errors[field] = validationResult[field][0];
								}
							}
							return errors;
						}}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={(values) => {
							saveEditedStudent(values);
						}}
					>
						{({ errors }) => (
							<Form className="instructor-classes--form">
								<ConnectedFocusError />
								<div className={buildClassList(["instructor-classes--form-wrapper", errors.studentName ? "error" : ""])}>
									<label htmlFor="edit-student-name">Name:</label>
									<span className="instructor-classes--form-input">
										<Field id="edit-student-name"
											name="studentName"
											type="text"
											placeholder="Required" />

										<ErrorMessage className="form-error" name="studentName" />
									</span>
								</div>

								<div className={buildClassList(["instructor-classes--form-wrapper", errors.studentEmail ? "error" : ""])}>
									<label htmlFor="edit-student-email">Email:</label>
									<span className="instructor-classes--form-input">
										<Field id="edit-student-email"
											name="studentEmail"
											type="text"
											placeholder="Required" />

										<span className="footnote">If the student does not have an email, provide a unique username</span>
										<ErrorMessage className="form-error" name="studentEmail" />
									</span>
								</div>

								{formError &&
									<div className="error form-error">{formError}</div>}

								<div className="instructor-classes--form-buttons">
									<Button variant="secondary" onClick={cancelEditStudent}>Cancel</Button>
									<Button variant="primary" type="submit">Submit</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>

			<Modal show={passwordModalOpen} onHide={cancelChangePassword} className="instructor-classes--change-password-modal">
				<Modal.Header closeButton={true} onHide={cancelChangePassword}>
					<Modal.Title>Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={passwordModalData}
						validate={(values) => {
							let errors = {};
							let validationResult = validate(values, passwordConstraints)

							for (const [field] of Object.entries(values)) {
								if (validationResult && validationResult[field]) {
									errors[field] = validationResult[field][0];
								}
							}
							return errors;
						}}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={(values) => {
							savePasswordChange(values);
						}}
					>
						{({ errors }) => (
							<Form className="instructor-classes--form">
								<ConnectedFocusError />
								<div className={buildClassList(["instructor-classes--form-wrapper", errors.password ? "error" : ""])}>
									<label htmlFor="edit-student-password">Password:</label>
									<span className="instructor-classes--form-input">
										<Field id="edit-student-password"
											name="password"
											type="password"
											placeholder="Required" />

										<ErrorMessage className="form-error" name="password" />
									</span>
								</div>

								<div className={buildClassList(["instructor-classes--form-wrapper", errors.passwordConfirm ? "error" : ""])}>
									<label htmlFor="edit-student-passwordConfirm">Confirm Password:</label>
									<span className="instructor-classes--form-input">
										<Field id="edit-student-passwordConfirm"
											name="passwordConfirm"
											type="password"
											placeholder="Required" />

										<ErrorMessage className="form-error" name="passwordConfirm" />
									</span>
								</div>

								<span className="instructor-add--input-group">
                                    <div id="password-policy">
                                        Passwords must meet the following criteria:
                                        <ul>
                                            <li>Must be 12 characters or longer</li>
                                            <li>Must include 1 or more lower case letters</li>
                                            <li>Must include 1 or more upper case letters</li>
                                            <li>Must include 1 or more digits</li>
                                            <li>Cannot match your email address</li>
                                        </ul>
                                    </div>
                                </span>

								<div className="instructor-classes--form-buttons">
									<Button variant="secondary" onClick={cancelChangePassword}>Cancel</Button>
									<Button variant="primary" type="submit">Submit</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>


		</>
	);
};

export default InstructorClassStudentsPage;