import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { buildClassList } from '../../../common/utils';
import { useDidUpdate } from 'rooks';

const SpinningWheelTerm = ({ prompt, term, definition, isWheelSpinning, ...props }) => {

	const [liveText, setLiveText] = useState('');

	useDidUpdate(() => {
		if (term && definition) {
			setLiveText(`${term}: ${definition}`);
		}
	}, [term, definition]);

	return (
		<div className="spinning-wheel--question">
			<div className="spinning-wheel--prompt">{prompt}</div>
			<div className="spinning-wheel--term--container">
				<div id="spinning-wheel-term" className={buildClassList(['spinning-wheel--term', isWheelSpinning ? 'spinning-wheel--spinning' : ''])}>
					{term}
				</div>
			</div>
			<div className="spinning-wheel--definition--container">
				<div className="spinning-wheel--definition">
					<div className={buildClassList(['spinning-wheel--definition--text', isWheelSpinning ? 'spinning-wheel--spinning' : ''])}>
						{definition}
					</div>
				</div>
			</div>

			<span className="sr-only" aria-live="polite">{liveText}</span>
		</div>
	);
};

SpinningWheelTerm.propTypes = {
	prompt: PropTypes.string.isRequired,
	term: PropTypes.string.isRequired,
	definition: PropTypes.string.isRequired,
	isWheelSpinning: PropTypes.bool
}

SpinningWheelTerm.defaultProps = {
	isWheelSpinning: false
}

export default SpinningWheelTerm;