import { useState } from "react";
import { useEffectOnceWhen, useIntervalWhen, useSessionstorageState } from "rooks";
import { formatTimeRemaining } from '../common/utils';

const useWaitTimer = (startValue) => {
	const [waitTime, setWaitTime, removeWaitTime] = useSessionstorageState("waitTime");
	const [isTimerActive, setIsTimerActive] = useState(false);
	const [formattedWaitTime, setFormattedWaitTime] = useState("");

	const decrement = () => {
		setWaitTime(waitTime - 1);
		setFormattedWaitTime(formatTimeRemaining(waitTime));
	}

	useIntervalWhen(() => {
		if (waitTime <= 0) {
			clearTimer();
		} else {
			decrement();
		}
	}, 1000, isTimerActive, true);

	useEffectOnceWhen(() => {
		setWaitTime(startValue);
		setIsTimerActive(true);
	}, startValue !== undefined)

	const clearTimer = () => {
		setIsTimerActive(false);
		removeWaitTime();
	}

	const isTimeRemaining = () => {
		return waitTime > 0;
	}

	return {
		isTimeRemaining,
		formattedWaitTime,
		clearTimer
	};
}

export default useWaitTimer;