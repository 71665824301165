import React from 'react';
import PropTypes from 'prop-types';
import Intro from './Intro/Intro';

const IntroView = ({ view, ...props }) => {

	return (
		<Intro text={view.text} />
	);
};

IntroView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['intro']),
		...Intro.propTypes
	}).isRequired
}

export default IntroView;