import React from 'react';
import './gateway-quiz.css';

const GatewayQuizSummaryQuestion = ({ question, questionNumber }) => {
	return (
        <>
            <li className='gateway-quiz-summary-question' key={`question-${questionNumber}`}>
                <div>{`Q. ${question.text}`}</div>
            </li>

            {question.answers.map((answer, index) =>
                <li className='gateway-quiz-summary-answer' key={`answer-${questionNumber}-${index}`}>
                    {answer.text &&
                        <div>{`A. ${answer.text}`}</div>
                    }
                    {answer.image && !answer.text &&
                        <div>{`A. ${answer.imageAlt}`}</div>
                    }
                    {answer.image &&
                        <img src={process.env.REACT_APP_URL + '/assets/images/activities' + answer.image} alt={answer.imageAlt} />
                    }
                </li>
            )}
        </>
    );
};

export default GatewayQuizSummaryQuestion;