import React from 'react';
import '../General/general-style.css';
import GatewayQuizSummaryQuestion from './GatewayQuizSummaryQuestion';

const GatewayQuizSummary = ({ questions }) => {
	return (
        <div className="">
            <div className='general--title'>Question Summary</div>

            <ul className='gateway-quiz-summary' role="radiogroup" aria-labelledby="legend">
                {questions.map((question, index) =>
                    <GatewayQuizSummaryQuestion question={question} questionNumber={index} />
                )}
            </ul>
        </div>
    );
};

export default GatewayQuizSummary;