import React from 'react';
import PropTypes from 'prop-types';
import GatewayQuizAnswerMarker from './GatewayQuizAnswerMarker';

const GatewayQuizHeader = ({ goal, answeredQuestions, submittedAnswers, ...props }) => {

	const submittedAnswer = submittedAnswers ? submittedAnswers[0] : null;

	return (
		<div className="topic--activity--gateway-quiz--container">
			<div className="topic--activity--gateway-quiz--goal">Goal: {goal} correct</div>
			
			<div className="topic--activity--gateway-quiz--markers">
				{answeredQuestions.map((answer, index) => <GatewayQuizAnswerMarker key={index} index={index + 1} isAnswered={true} isCorrect={answer} />)}

				<GatewayQuizAnswerMarker isAnswered={submittedAnswer ? true : false} 
					isCorrect={submittedAnswer && submittedAnswer.correct && submittedAnswer.correct.includes(submittedAnswer.answer[0])} />
			</div>
		</div>
	);
};

GatewayQuizHeader.propTypes = {
	goal: PropTypes.number,
	answeredQuestions: PropTypes.arrayOf(PropTypes.bool),
	submittedAnswers: PropTypes.any
}

GatewayQuizHeader.defaultProps = {
	answeredQuestions: []
}

export default GatewayQuizHeader;