export const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^The Name field is empty; it is a required field and must be filled in."
        }
    },
    email: {
        presence: {
            allowEmpty: false,
            message: "^The Email field is empty; it is a required field and must be filled in."
        },
        email: {
            message: "^The Email field is in the wrong format; please use a valid email address."
        }
    },
    emailConfirm: {
        presence: {
            allowEmpty: false,
            message: "^The Confirm Email field is empty; it is a required field and must be filled in."
        },
        equality: {
            attribute: "email",
            message: "^The Confirm Email field does not match the Email field."
        }
    },
    password: {
        presence: {
            allowEmpty: false,
            message: "^The Password field is empty; it is a required field and must be filled in."
        },
        passwordCriteria: true
    },
    passwordConfirm: {
        presence: {
            allowEmpty: false,
            message: "^The Confirm Password field is empty; it is a required field and must be filled in."
        },
        equality: {
            attribute: "password",
            message: "^The Confirm Password field does not match the Password field."
        }
    }
}

export default constraints;