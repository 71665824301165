import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './ProvideAuth';

const EnforceStudent = ({ children, ...props }) => {
	let auth = useAuth();
	return (
		<Route {...props} render={({ location }) => {
			return auth.user && !auth.user.isInstructor ? children :
				<Redirect to={{
					pathname: '/',
					state: { from: location }
				}} />
		}} />
	);
};

export default EnforceStudent;
