import React from 'react';
import PropTypes from 'prop-types';
import { ShieldLock } from 'react-bootstrap-icons';
import { useAuth } from '../../common/ProvideAuth';

const TopicCard = ({ unitNumber, title, locked, progress, iniitalActivityId, ...props }) => {

	const auth = useAuth();

	const openActivity = () => {
		if (!locked) {
			auth.viewActivity(iniitalActivityId);
		}
	}

	const buildAriaLabel = () => {
		var label = `Unit ${unitNumber} Topic: ${title}`;
		if (locked) {
			label += "; Topic is locked";
		} else {
			if (progress === 0) {
				label += `; Topic not started`;
			} else {
				label += `; Topic is ${progress} percent complete`;
			}

			label += "; click to enter the topic.";
		}

		return label;
	}

	if (locked) {
		return (
			<div className="profile--unit-card--topic--locked" role="group" aria-label={buildAriaLabel()}>
				<h4 className="sr-only">{buildAriaLabel()}</h4>
				<div className="profile--unit-card--topic light">
					<div className="profile--unit-card--topic--title profile--unit-card--topic--title--locked">
						{title}
					</div>
					<ShieldLock className="profile--unit-card--topic--locked--glyph" />
				</div>
			</div>
		);
	} else {
		return (
			<div className="profile--unit-card--topic--active">
				<button href="#" onClick={openActivity} aria-label={buildAriaLabel()}>
					<h4 className="sr-only">{buildAriaLabel()}</h4>
					<div className="profile--unit-card--topic light">
						<div className="profile--unit-card--topic--title">
							{title}
						</div>

						<div className="profile--unit-card--topic--progress-bar--container" aria-hidden="true">
							<div className="progress">
								<div
									className="progress-bar"
									role="progressbar"
									style={{ width: progress + "%" }}
									aria-valuenow={progress}
									aria-valuemin="0"
									aria-valuemax="100">
								</div>
							</div>
						</div>
					</div>
				</button>
			</div>
		);
	}
};

TopicCard.propTypes = {
	title: PropTypes.string,
	locked: PropTypes.bool,
	progress: PropTypes.number,
	iniitalActivityId: PropTypes.string
}

TopicCard.defaultProps = {
	title: "Example Topic",
	locked: true,
	progress: 0,
	iniitalActivityId: null
}

export default TopicCard;