import React from 'react';
import PropTypes from 'prop-types';

const PlainModalBody = ({ title, image, text, ...props }) => {

	return (
		<div className="interactive-graphic--modal--container">
			<img className="interactive-graphic--modal--image"
				src={process.env.REACT_APP_URL + '/assets/images/activities' + image} 
				alt={title} />

			<div className="interactive-graphic--modal--text">{text}</div>
		</div>
	);
};

PlainModalBody.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	text: PropTypes.string
}

export default PlainModalBody;