import React from 'react';
import './spinning-wheel-style.css';
import { Button } from 'react-bootstrap';
import SpinningWheel from './SpinningWheel';
import SpinningWheelTerm from './SpinningWheelTerm';
import { Formik, Form, ErrorMessage } from 'formik';
import useSpinningWheel from './useSpinningWheel';
import QuestionAnswerBubble from '../../QuestionAnswerBubble';
import { useDidMount, useDidUpdate } from 'rooks';
import useSubmitFeedback from '../../../hooks/useSubmitFeedback';

const SpinningWheelQuestion = ({ prompt, foreground, background, rotations, question, questionNumber, answers, isGateway, inputType, handleSubmit, submittedAnswer, buttonHelper, ...props }) => {

	const { currentIndex, setCurrentIndex } = useSpinningWheel();
	const [submitFeedback] = useSubmitFeedback(submittedAnswer);
	const gateway = isGateway();

	const handleAnswerClick = (event) => {
		setCurrentIndex(parseInt(event.target.value, 10) + 1);
	};

	const hasSubmittedAnswer = () => {
		return submittedAnswer !== null && typeof submittedAnswer !== 'undefined';
	};

	const checkNextButton = () => {
		if (hasSubmittedAnswer()) {
			buttonHelper.enableNextButton();
		} else {
			buttonHelper.disableNextButton();
		}
	};

	useDidMount(() => {
		checkNextButton();
	});

	useDidUpdate(() => {
		checkNextButton();
	}, [submittedAnswer]);

	return (
		<div className="spinning-wheel--container">
			<SpinningWheelTerm prompt={prompt} term={question.term} definition={question.definition} />

			<SpinningWheel foreground={foreground} background={background} rotations={rotations} rotationIndex={currentIndex} />

			<Formik
				initialValues={{
					'0': (hasSubmittedAnswer() ? `${submittedAnswer.answer[0]}` : undefined)
				}}
				validate={(values) => {
					const errors = {};
					if (!values['0']) {
						errors['0'] = "Please select an answer and try again."
					}
					return errors;
				}}
				validateOnBlur={false}
				onSubmit={(values) => {
					handleSubmit(values);
				}}
			>
				<Form>
					<fieldset>
						<legend className="sr-only">{`${question.term}: ${question.definition}`}</legend>
						<div role="group" aria-labelledby="spinning-wheel-term" className="answer-container horizontal">
							{
								answers.map((answer, index) =>
									<QuestionAnswerBubble key={index}
										inputType={inputType}
										inputGroupName="0"
										answerNumber={index}
										answerText={answer.text}
										submittedAnswer={submittedAnswer}
										handleAnswerSelected={handleAnswerClick}
										gateway={gateway}
									/>
								)
							}
						</div>
					</fieldset>

					<span className="error form-error" aria-live="polite">
						<ErrorMessage name="0" />
					</span>
					<span className="sr-only" aria-live="polite">
						{submitFeedback &&
							<span>{submitFeedback}</span>
						}
					</span>
					<Button className="float-end" variant="light" type="submit" disabled={submittedAnswer}>Submit</Button>
				</Form>
			</Formik>
		</div>
	);
};

export default SpinningWheelQuestion;