import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { validate } from 'validate.js';
import constraints from './forgot-password-constraints';
import './login-style.css';

const ForgotPasswordForm = ({handleSubmit, ...props}) => {
	const [submittedEmail, setSubmittedEmail] = useState(props.submittedEmail || '');
	const [submitError, setSubmitError] = useState('');

	return (
		<div className="login--form--container">
			<h2>Forgot Password</h2>

			{submittedEmail &&
				<div className="forgot-password--confirmation">
					<p>If <em>{submittedEmail}</em> belongs to an account, a password reset link has been emailed.  Please follow the instructions in the email to set a new password.</p>
					<Button href="/login" variant="primary">Return to Login</Button>
				</div>
			}

			{!submittedEmail &&
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{ email: '' }}
					validate={(values) => {
						const errors = {};
						let validationResult = validate(values, constraints)
						if (typeof validationResult !== 'undefined') {
							errors.email = validationResult.email[0];
						}
						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(values.email).then(
							result => {
								setSubmitting(false);
								setSubmittedEmail(values.email);
							},
							error => setSubmitError(error)
						);
					}}>
					
					{ formik =>
						<Form className="login--form">
							<span className={["login--form--input-group", (formik.errors.email || submitError) && "error"].filter(e => !!e).join(' ')}>
								<label htmlFor="email">Enter your email address</label>
								<div className="login--form--input">
									<Field 
										className="login--input" 
										id="email"
										aria-describedby="email-error email-submit-error"
										placeholder="Required"
										name="email" 
										type="text" />
									
									<span id="email-error" className="form-error">
										<ErrorMessage name="email" />
									</span>
									<span id="email-submit-error" className="form-error">{submitError}</span>
								</div>
							</span>
							<div className="login--button--container">
								<Container>
									<Row xs="1" sm="2">
										<Col xs={{ order: 'last' }} className="px-0"><Button className="float-end" variant="primary" type="submit">Submit</Button></Col>
										<Col xs={{ order: 'first' }} className="px-0"><Button className="float-start" variant="secondary" href="/login">Cancel</Button></Col>
									</Row>
								</Container>
							</div>
						</Form>
					}
				</Formik>
			}
		</div>
	);
};

ForgotPasswordForm.propTypes = {
	submitted: PropTypes.bool,
	handleSubmit: PropTypes.func // returns a Promise
};

ForgotPasswordForm.defaultProps = {
	submitted: false,
	handleSubmit: function (values) {
		return new Promise((resolve, reject) => {
			setTimeout(() => resolve("done!"), 1000);
		});
	}
};

export default ForgotPasswordForm;