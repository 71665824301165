import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const ActivityButton = ({ clickHandler, disabled, text, ...props }) => {

	return (
		<div className="topic--activity--nav--button">
			<Button variant="light" onClick={clickHandler} disabled={disabled}>{text}</Button>
		</div>
	);
};

ActivityButton.propTypes = {
	clickHandler: PropTypes.func,
	disabled: PropTypes.bool,
	text: PropTypes.string
}

ActivityButton.defaultProps = {
	clickHandler: function() {
	},
	disabled: true,
	text: 'Button'
}

export default ActivityButton;