import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './interactive-model-style.css';
import { PlayFill } from 'react-bootstrap-icons';
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';

const InteractiveModel = ({ prompt, title, src, placeholder, supplemental, ...props }) => {

	const [isLoaded, setIsLoaded] = useState(false);

	const activateModel = () => {
		setIsLoaded(true);
	}

	return (
		<div className="interactive-model--container">
			<div className="interactive-model-prompt">{prompt}</div>
			{supplemental &&
				<ActivitySupplementalInfo label={supplemental.label} content={supplemental.content} />
			}
			{!isLoaded &&
				<div className="interactive-model--placeholder">
					<img src={process.env.REACT_APP_URL + placeholder} alt={title} />
					<button className="play-button" aria-label="Load interactive activity" onClick={activateModel}><PlayFill></PlayFill></button>
				</div>
			}
			{isLoaded &&
				<iframe
					title={title}
					src={process.env.REACT_APP_URL + src}
					width="800"
					height="500"
					frameBorder="0"
					allowFullScreen
				></iframe>
			}
		</div>
	);
};

InteractiveModel.propTypes = {
	prompt: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired
}

export default InteractiveModel;