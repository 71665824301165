import React from 'react';
import PropTypes from 'prop-types';
import { buildClassList } from '../../common/utils';

const Trophy = ({ trophy, disabled, handleSelection, ...props }) => {

	return (
		<div className="trophy--container">
			<input
				onChange={handleSelection}
				id={trophy.id}
				className="trophy--input"
				type="checkbox"
				name="trophies"
				value={trophy.id}
				defaultChecked={trophy.displayed}
				disabled={!trophy.earned || disabled}/>
			<label className={buildClassList(["trophy--label", !trophy.earned ? "trophy--label--locked" : ""])} htmlFor={trophy.id}>
				<div className="trophy--outline">
					<div className="trophy--info">
						<h4>{trophy.name}</h4>
						<div className="trophy--description">{trophy.description}</div>
					</div>
					<img className="trophy--image" src={`${process.env.REACT_APP_URL}/assets/images/trophies${trophy.image}`} alt={`${trophy.name}: ${trophy.description}`} />
				</div>
			</label>
		</div>
	);
};

Trophy.propTypes = {
	trophy: PropTypes.shape({
		id: PropTypes.string,
		displayed: PropTypes.bool,
		earned: PropTypes.bool,
		name: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string
	}),
	disabled: PropTypes.bool,
	handleSelection: PropTypes.func
}

export default Trophy;