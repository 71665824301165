import React from 'react';
import PropTypes from 'prop-types';
import SortingAnswer from './SortingAnswer';
import SortingRow from './SortingRow';
import './sorting-style.css';

const Sorting = ({ prompt, answers, questions, ...props }) => {

	return (
		<div className="sorting--question">
			<div className="sorting--prompt">{prompt}</div>
			<div className="sorting--categories">
				{
					answers.map((answer, index) => <SortingAnswer key={index} text={answer.text} />)
				}
			</div>
			{
				questions.map((question, index) =>
					<SortingRow key={index}
						questionNumber={index}
						question={question} />
				)
			}
		</div>
	);
};

Sorting.propTypes = {
	prompt: PropTypes.string,
	answers: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string
	})),
	questions: PropTypes.arrayOf(SortingRow.propTypes.question)
}

export default Sorting;