import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

export const Loader = ({small, animation, variant, message, ...props}) => {
	const size = small ? "sm" : "";
	return (
		<Spinner animation={animation} role="status" size={size} variant={variant}>
			<span className="visually-hidden">{message}</span>
		</Spinner>
	);
};

Loader.propTypes = {
	small: PropTypes.bool,
	animation: PropTypes.string,
	variant: PropTypes.string,
	message: PropTypes.string
};

Loader.defaultProps = {
	small: false,
	animation: "border",
	variant: undefined,
	message: "Loading..."
};