import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm';
import { useAuth } from '../common/ProvideAuth';
import useTitle from '../hooks/useTitle';

const LoginPage = () => {
	const history = useHistory();
	const auth = useAuth();

	useTitle('Vision of You Login Page');

	useEffect(() => {
		if (auth.currentActivity) {
			auth.viewActivity(null);
		}
	});

	const doLogin = (username, password) => {
		return auth.doLogin(username, password)
			.then((message) => {
				history.replace("/profile");
			});
	}

	return (
		<div className="login--container">
			<div className="login--logo--container">
				{/* TODO: replace host for images */}
				<img
					className="login--logo"
					src={process.env.REACT_APP_URL + '/assets/images/app/logo-bg.png'}
					alt="Vision of You"
				/>
			</div>
			
			<LoginForm doLogin={doLogin} loginWarning={auth.logoutReason} />

			<div className='d-flex flex-column align-items-center mt-5'>
				<img className='w-25' src={process.env.REACT_APP_URL + '/assets/images/app/SexEdVA_Logo_White-Text.png'} alt=""/>
        		<img className='w-25' src={process.env.REACT_APP_URL + '/assets/images/app/JMU-IIHHS_horiz_black.png'} alt=""/>
			</div>
		</div>
	);
}

export default LoginPage;