import React from 'react';
import PropTypes from 'prop-types';
import SortingOption from './SortingOption';

const SortingRow = ({ question, questionNumber, ...props }) => {

	const positions = ['arrow-left', 'arrow-right'];

	return (
		<div className="sorting--row">
			<div className="sorting--question--text">{question.text}</div>
			{
				positions.map((position, index) => 
					<SortingOption key={index}
						questionNumber={questionNumber}
						answerNumber={index}
						position={position}
						image={question.image}
						text={question.text} />
				)
			}
		</div>
	);
};

SortingRow.propTypes = {
	question: PropTypes.shape({
		text: PropTypes.string,
		image: PropTypes.string
	}),
	questionNumber: PropTypes.number
}

export default SortingRow;