import React from 'react';
import PropTypes from 'prop-types';
import EnterText from './EnterText/EnterText';

const EnterTextView = ({ view, submittedAnswers, activityHelper, ...props }) => {

	const question = view.questions[0];
	const submittedAnswer = submittedAnswers ? submittedAnswers[question.questionNumber] : null;

	var submittedText = '';
	if (submittedAnswer && submittedAnswer.answer && submittedAnswer.answer.length > 0) {
		submittedText = submittedAnswer.answer[0];
	}

	const buttonHelper = {enableNextButton: activityHelper.enableNextButton, disableNextButton: activityHelper.disableNextButton};

	return (
		<EnterText question={question} submittedText={submittedText} wordMinCount={view.wordMinCount} handleSubmit={activityHelper.handleSubmit} profanityFilter={view.profanityList} filterList={view.filterList} buttonHelper={buttonHelper}/>
	);
};

EnterTextView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['enterText']),
		...EnterText.propTypes
	}).isRequired,
	submittedAnswers: PropTypes.arrayOf(PropTypes.shape({
		answer: PropTypes.array,
		evaluated: PropTypes.bool
	}))
}

export default EnterTextView;