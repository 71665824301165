import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';

const ProfileModalOverlay = ({ name, coordinates, element, ...props }) => {

	return (
		<OverlayTrigger
			trigger={['hover', 'focus']}
			placement="bottom"
			overlay={element}
		>
			<div
				id={'profile--modal--' + name}
				className="profile--modal--hover-area"
				style={{
					left: coordinates[0],
					top: coordinates[1],
					width: coordinates[2] - coordinates[0],
					height: coordinates[3] - coordinates[1]
				}}
			/>
		</OverlayTrigger>
	);
};

ProfileModalOverlay.propTypes = {
	name: PropTypes.string,
	coordinates: PropTypes.array,
	element: PropTypes.element
}

ProfileModalOverlay.defaultProps = {
	name: "Profile Modal Overlay",
	coordinates: [0, 0, 50, 50],
	element: <h1>Profile Modal Overlay</h1>
}

export default ProfileModalOverlay;