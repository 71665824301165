import React from 'react';
import PropTypes from 'prop-types';
import Labelling from './Labelling/Labelling';

const LabellingView = ({ view, ...props }) => {

	return (
		<Labelling prompt={view.prompt} categories={view.categories} questions={view.questions} answers={view.answers} />
	);
};

LabellingView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['labelling']),
		...Labelling.propTypes
	}).isRequired
}

export default LabellingView;