import React from 'react';
import QuestionAnswerBubble from '../../QuestionAnswerBubble'
import ActivitySupplementalInfo from '../../ActivitySupplementalInfo';
import useSubmitFeedback from '../../../hooks/useSubmitFeedback';

const QuizQuestion = ({ question, inputType = 'radio', answers, selectedAnswers = [], condition = null, warning = '', conditionsNotMet = false, handleChange, submittedAnswer, errors, isDisableUnselected = false, isGateway, ...props }) => {

	const [submitFeedback] = useSubmitFeedback(submittedAnswer);
	const gateway = isGateway();

	const buildAnswers = () => {
		let allReqs = [];
		let reqs = [];
		if (condition && condition.requirements) {
			// reqs = condition.requirements.map(r => r.toString());
			reqs = condition.requirements
			reqs.forEach(function (item, index) {
				// We want to avoid highlighting a group as long as one of the answers from the group is selected
				// We can accomplish that by not including the group of requirements in the list of all requirements
				// if that group's requirement is already met.
				let exists = selectedAnswers ? selectedAnswers.some((val) => item.indexOf(val) !== -1) : false;
				if (!exists) {
					allReqs = allReqs.concat(item);
				}
			});
		}

		let elements = [];
		let currentGroup = [];
		var groupIndex = 1;
		for (let i = 0; i < answers.length; i++) {
			let answer = answers[i];
			if (conditionsNotMet && allReqs.indexOf(i) >= 0) {
				for (let index = 0; index < reqs.length; index++) {
					const item = reqs[index];
					let reqIndex = item.indexOf(i);
					if (reqIndex === 0) {
						currentGroup = [];
						pushAnswer(currentGroup, answer, i);
					} else if (reqIndex > 0 && reqIndex < item.length - 1) {
						pushAnswer(currentGroup, answer, i);
					} else if (reqIndex === item.length - 1) {
						pushAnswer(currentGroup, answer, i)
						groupIndex++
						elements.push(<div className="quiz--question--answer--group" key={'group-' + groupIndex}>{currentGroup}</div>)
					}
				};
			} else {
				pushAnswer(elements, answer, i);
			}
		}

		return elements;
	}

	const pushAnswer = (elements, answer, answerIndex) => {
		elements.push(
			<li key={`question-${question.questionNumber}-answer-${answerIndex}`}>
				<QuestionAnswerBubble
					inputType={inputType}
					handleAnswerSelected={handleChange}
					inputGroupName={question.questionNumber.toString()}
					answerNumber={answerIndex}
					answerText={answer.text}
					forceDisable={!selectedAnswers.includes(`${answerIndex}`) && isDisableUnselected}
					gateway={gateway}
					submittedAnswer={submittedAnswer}
				/>
			</li>
		);
	}

	return (
		<div className="quiz--question">
			<div className="question-image-wrapper">
				{question.image &&
					<img className="quiz--question--image"
						src={`${process.env.REACT_APP_URL}/assets/images/activities${question.image}`}
						alt={question.imageAlt ? question.imageAlt : question.text}
					/>
				}

				{question.supplemental &&
					<ActivitySupplementalInfo label={question.supplemental.label} content={question.supplemental.content} />
				}
			</div>

			<div className="quiz--answers">
				<div id={`legend-${question.questionNumber}`} className="quiz--question--text">{question.text}</div>

				<ul role={inputType === 'radio' ? "radiogroup" : "group"} className="answer-container" aria-labelledby={`legend-${question.questionNumber}`}>
					{buildAnswers()}
				</ul>
				<span className="form-error ms-5 me-5 text-center" aria-live="polite">
					{warning &&
						<span>{warning}</span>
					}
				</span>
				<span className="form-error" aria-live="polite">
					{errors[question.questionNumber.toString()] &&
						<span>{errors[question.questionNumber]}</span>
					}
				</span>
				<span className="sr-only" aria-live="polite">
					{submitFeedback &&
						<span>{submitFeedback}</span>
					}
				</span>
			</div>
		</div>
	);
};

QuizQuestion.propTypes = {
}

QuizQuestion.defaultProps = {
}

export default QuizQuestion;