import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import App from './App';
import ProfilePage from './Profile/ProfilePage';
import ProvideAuth from './common/ProvideAuth';
import reportWebVitals from './reportWebVitals';

import LoginPage from './Login/LoginPage';

import 'bootstrap/dist/css/bootstrap.css'
import 'normalize.css'
import './static/style.css'
import NotFound from './common/NotFound';
import PasswordManagementPage from './Login/PasswordManagementPage';
import TopicContainer from './Activity/TopicContainer';
import TrophyContainer from './Profile/Trophy/TrophyContainer';
import InstructorSetupPage from './Instructors/InstructorSetupPage';
import StudentSetupPage from './Instructors/StudentSetupPage';

import EnforceStudent from './common/EnforceStudent';
import EnforceInstructor from './common/EnforceInstructor';
import StudentOrInstructor from './common/StudentOrInstructor';
import InstructorClassesPage from './Instructors/Dashboard/InstructorClassesPage';
import InstructorClassStudentsPage from './Instructors/Dashboard/InstructorClassStudentsPage';
import StudentProgressPage from './Instructors/Dashboard/StudentProgressPage';

const Routing = () => {
  return (
    <Router>
      <ProvideAuth>
        <App>
          <Switch>
            <Route exact path="/instructor/setup/:locationCode" name="instructor-setup">
              <InstructorSetupPage />
            </Route>
            <Route exact path="/student/setup/:classCode" name="student-setup">
              <StudentSetupPage />
            </Route>

            <Route exact path="/">
              <StudentOrInstructor />
            </Route>

            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route path="/password/:refId?">
              <PasswordManagementPage />
            </Route>


            <EnforceStudent exact path="/profile">
              <ProfilePage />
            </EnforceStudent>
            <EnforceStudent exact path="/activity">
              <TopicContainer />
            </EnforceStudent>
            <EnforceStudent exact path="/trophies">
              <TrophyContainer />
            </EnforceStudent>

            <EnforceInstructor exact path="/instructor/dashboard/classes">
              <InstructorClassesPage />
            </EnforceInstructor>
            <EnforceInstructor path="/instructor/dashboard/class/:classId/students">
              <InstructorClassStudentsPage />
            </EnforceInstructor>
            <EnforceInstructor path="/instructor/dashboard/class/:classId/student/:studentId">
              <StudentProgressPage />
            </EnforceInstructor>

            <Route>
              <NotFound />
            </Route>

          </Switch>
        </App>
      </ProvideAuth>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
