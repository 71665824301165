import React, { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useAuth } from '../common/ProvideAuth';
import { submitReport } from '../api/report.js';
import useModal from '../hooks/useModal';
import Video from '../Activity/View/Video/Video';
import { welcomeVideoSeen } from '../api/activity';
import { useDidMount } from 'rooks';

const ReportProblem = ({ ...props }) => {

	const auth = useAuth();
	const { show, handleClose, handleShow } = useModal();
	const { show: showVideo, handleClose: hideVideoModal, handleShow: showVideoModal } = useModal();
	const [showWarning, setShowWarning] = useState(false);

	const showModal = () => {
		setShowWarning(false);
		handleShow();
	};

	useDidMount(() => {
		if (auth.user && !auth.user.welcomeVideoSeen) {
			showVideoModal();
		}
	});

	const markWelcomeVideoSeen = () => {
		welcomeVideoSeen(auth.user.authToken).finally(() => {
			auth.markWelcomeVideoSeen();
			hideVideoModal();
		});
	}

	return (
		<>
			<div className="topic--report-problem--modal--container">
				<Button variant="link" onClick={showVideoModal}>Welcome Video</Button>
				<Button variant="link" onClick={showModal}>Report a problem</Button>
			</div>

			<Modal show={showVideo} onHide={markWelcomeVideoSeen}>
				<Modal.Header closeButton={true}>Welcome Video</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-center">
						<Video src={auth.user.welcomeVideo} />
					</div>
				</Modal.Body>
			</Modal>

			<Modal className="topic--report-problem--modal" show={show} onHide={handleClose}>
				<Modal.Body>
					<Formik
						initialValues={{
							type: '',
							message: ''
						}}
						onSubmit={(values) => {
							if (values.message === '' || values.type === '') {
								setShowWarning(true);
							} else {
								submitReport(auth.user.authToken, values.type, values.message)
									.then(response => {
										handleClose();
									});
							}
						}}
					>
						<Form>
							<ul>
								<li>
									<Field type="radio" id="content" name="type" value="content" />
									<label htmlFor="content">Content</label>
								</li>
								<li>
									<Field type="radio" id="bug" name="type" value="bug" />
									<label htmlFor="bug">Bug</label>
								</li>
								<li>
									<Field type="radio" id="other" name="type" value="other" />
									<label htmlFor="other">Other</label>
								</li>
							</ul>

							<p>Please describe the nature of the problem:</p>

							<div className="topic--report-problem--text-area--container">
								<Field component="textarea" id="message" className="topic--report-problem--text-area" name="message" />
							</div>

							{showWarning &&
								<div className="topic--report-problem--modal--warning">Please select the type of error and detail the error in the provided space above</div>
							}
							<div className="topic--report-problem--modal--buttons">
								<Button variant="secondary" onClick={handleClose}>Close</Button>
								<Button variant="primary" type="submit">Send</Button>
							</div>
						</Form>
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ReportProblem;