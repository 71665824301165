import React from 'react';

export const Footer = (props) => {
  return (
    <footer className="dark">
      <div>Copyright JMU, IIHHS, SexEdVA</div>
    </footer>
  )
}

export default Footer
