import React, { useState, useRef, useEffect } from 'react';
import { useDidMount } from 'rooks';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { fetchInstructorClasses, saveInstructorClass } from '../../api/instructors';
import { useAuth } from '../../common/ProvideAuth';
import { Loader } from '../../common/Loader';
import InstructorClassesTable from './InstructorClassesTable';
import useModal from '../../hooks/useModal';
import { buildClassList } from '../../common/utils';
import { validate } from 'validate.js';

import './instructor-classes.css';
import 'react-toastify/dist/ReactToastify.css';
import constraints from './class-constraints';

const InstructorClassesPage = ({ ...props }) => {

	const auth = useAuth();
	const { show, handleClose, handleShow } = useModal();
	const [isFetching, setIsFetching] = useState(true);
	const [classes, setClasses] = useState({ active: [], inactive: [] });
	const [errorMessage, setErrorMessage] = useState('');
	const [editingClass, setEditingClass] = useState({ id: null, name: '' });
	const inputElement = useRef(null);

	useDidMount(() => {
		loadInstructorClasses();
	});

	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);

	const loadInstructorClasses = () => {
		setIsFetching(true);
		fetchInstructorClasses(auth.user.authToken).then(function (response) {
			switch (response.data.code) {
				case 200:
					setIsFetching(false);
					setClasses(response.data.classes);
					break;

				default:
					auth.sessionTimeout();
			}
		});
	};

	const cancel = () => {
		handleClose();
		clearEditingClass();
		loadInstructorClasses();
	};

	const save = (voyClass) => {
		let error = validateForm();
		if (error) {
			setErrorMessage(error);
		} else {
			persistInsructorClass(voyClass);
		}
	};

	const persistInsructorClass = (voyClass) => {
		saveInstructorClass(auth.user.authToken, voyClass).then(function (response) {
			switch (response.data.code) {
				case 200:
					cancel();
					break;
				case 302:
					setErrorMessage("A class with the provided name already exists.");
					break;
				case 500:
					setErrorMessage("There was an unknown error adding the class.");
					break;
				default:
					auth.sessionTimeout();
					break;
			}
		});
	};

	const addClass = () => {
		setEditingClass({ id: null, name: '', active: true });
		handleShow();
	};

	const clearEditingClass = () => {
		setEditingClass({ id: null, name: '' });
		setErrorMessage('');
	};

	const editClass = (voyClass) => {
		setEditingClass({ id: voyClass.id, name: voyClass.name, active: voyClass.active });
		handleShow();
	};

	const toggleStatus = (voyClass) => {
		voyClass.active = voyClass.active === 1 ? 0 : 1;
		persistInsructorClass(voyClass);
	};

	const validateForm = () => {
		let validationResult = validate({ name: editingClass.name }, constraints)
		if (validationResult && validationResult['name']) {
			return validationResult['name'][0];
		}

		return null;
	};

	const handleNameUpdate = (event) => {
		setEditingClass({ ...editingClass, name: event.target.value });
	}

	const copySignup = (voyClass) => {
		let url = "https://visionofyou.org/student/setup/" + voyClass.classCode;
		if (navigator.clipboard) {
			navigator.clipboard.writeText(url).then(function () {
				toast.success("Sign Up Link Copied to Clipboard!");
			}, function () {
				toast.error("Clipboard Copy Failed. " + url, { 'autoClose': false, 'closeOnClick': false, 'draggable': false });
			});
		} else {
			toast.error("Clipboard Copy Failed. " + url, { 'autoClose': false, 'closeOnClick': false, 'draggable': false });
		}
	}

	const createActionsMenu = (row) => {
		let statusString = row.active === 1 ? 'Deactivate' : 'Activate';
		return (
			<DropdownButton id={"instructor-classes--actions-" + row.id} size="sm" title="Actions" variant="outline-secondary">
				<Dropdown.Item as="button" onClick={() => editClass(row)}>Edit</Dropdown.Item>
				<Dropdown.Item as="button" onClick={() => toggleStatus(row)}>{statusString}</Dropdown.Item>
				<Dropdown.Item as="button" onClick={() => copySignup(row)}>Copy Sign Up URL</Dropdown.Item>
			</DropdownButton>
		);
	};

	return (
		<>
			{isFetching &&
				<div className="loader--container">
					<Loader />
				</div>
			}

			{!isFetching &&
				<div className="instructor-classes">
					<div className="instructor-classes--header pb-3">
						<h2>Classes</h2>
						<a href={process.env.REACT_APP_URL + '/assets/pdfs/instructor-guide.pdf'} alt="Guide for instructor dashboard" target="_blank" rel="noopener noreferrer">Instructor Guide</a>
					</div>

					<div className="instructor-classes--table">
						<div className="instructor-classes--table-header">
							<h3>Active</h3>
							<Button variant="primary" onClick={addClass}>Add Class</Button>
						</div>
						<InstructorClassesTable voyClasses={classes.active} actionsMenu={createActionsMenu} />
					</div>

					<div className="instructor-classes--table">
						<h3>Inactive</h3>
						<InstructorClassesTable voyClasses={classes.inactive} actionsMenu={createActionsMenu} />
					</div>

					<ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} closeOnClick />
				</div>
			}

			<Modal show={show} onHide={cancel} className="instructor-classes--modal">
				<Modal.Header closeButton={true} onHide={cancel}>
					<Modal.Title>{editingClass.id == null ? "Add Class" : "Edit Class"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="instructor-classes--form">
						<div className={buildClassList(["instructor-classes--form-wrapper", errorMessage ? "error" : ""])}>
							<label htmlFor="add-class-name">Name:</label>
							<span className="instructor-classes--form-input">
								<input id="add-class-name"
									type="text"
									aria-describedby="name-error"
									onChange={handleNameUpdate}
									placeholder="Required"
									ref={inputElement}
									value={editingClass.name} />
								<span id="name-error" className="form-error">{errorMessage ? errorMessage : ""}</span>
							</span>
						</div>

						<div className="instructor-classes--form-buttons">
							<Button variant="secondary" onClick={cancel}>Cancel</Button>
							<Button variant="primary" onClick={() => save(editingClass)}>Submit</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InstructorClassesPage;