import React from 'react';
import { Header } from './common/Header';
import { Footer } from './common/Footer';
import { useAuth } from './common/ProvideAuth';
import { Button, Modal } from 'react-bootstrap';
import useModal from './hooks/useModal';
import { useKonami } from 'react-konami-code';

const App = (props) => {
	let auth = useAuth();

	const { show, handleClose, handleShow } = useModal();
	useKonami(handleShow);

	return (
		<div className="site--container light">
			<Header isLoggedIn={auth.user != null} />
			<main className="main--container">
				<h1 className="sr-only">Vision of You</h1>
				{props.children}
			</main>
			<Footer />

			<Modal className="d-flex p-5" show={show} onHide={handleClose}>
				<Modal.Body>
					<figure>
						<figcaption>You entered the secret code. Enjoy!</figcaption>
						<audio
							controls
							src={
								process.env.REACT_APP_URL +
								'/assets/audio/Gradius_NES_Music.mp3'
							}>
							Your browser does not support the
							<code>audio</code> element. You're missing out on some sweet Nintendo action.
						</audio>
					</figure>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>

		</div>
	)
}

export default App
