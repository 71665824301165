import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Trophy from './Trophy';
import useTitle from '../../hooks/useTitle';
import { resetKeyboardFocus } from '../../common/utils';
import { useEffectOnceWhen } from 'rooks';

const TrophyPage = ({ trophies, saveTrophies, ...props }) => {

	const [disableUnselected, setDisableUnselected] = useState(false);
	const [selectedTrophies, setSelectedTrophies] = useState(trophies.filter(trophy => trophy.displayed).map((trophy, index) => trophy.id));

	useTitle('Vision of You Trophy Selection');

	useEffectOnceWhen(() => {
		resetKeyboardFocus();
	}, true);

	const handleTrophySelection = (event) => {
		let clickedTrophy = event.target.id;
		if (selectedTrophies.includes(clickedTrophy)) {
			let arrayCopy = selectedTrophies.slice();
			let index = arrayCopy.indexOf(clickedTrophy);
			if (index > -1) {
				arrayCopy.splice(index, 1);
			}

			setSelectedTrophies(arrayCopy);
		} else {
			let arrayCopy = selectedTrophies.slice();
			arrayCopy.push(clickedTrophy);
			setSelectedTrophies(arrayCopy);
		}
	}

	const saveSelectedTrophies = () => {
		saveTrophies(selectedTrophies);
	}

	useEffect(() => {
		if (selectedTrophies.length >= 3) {
			setDisableUnselected(true);
		} else {
			setDisableUnselected(false);
		}
	}, [selectedTrophies]);

	return (
		<div id="main-content" className="trophy-page--container dark">
			<div className="trophy--header">
				<h2>Trophy Room</h2>
				<h3 id="purpose">Select up to 3 trophies to display on your home page.</h3>
			</div>

			<fieldset className="trophy--list--container">
				<legend aria-labelledby="purpose"></legend>
				{
					trophies.map((trophy, index) => <Trophy trophy={trophy} key={index} disabled={selectedTrophies.includes(trophy.id) ? false : disableUnselected} handleSelection={handleTrophySelection} />)
				}
			</fieldset>

			<div className="trophy--buttons">
				<a className="btn btn-secondary" href="/" role="button">Cancel</a>
				<Button variant="light" onClick={saveSelectedTrophies}>Save</Button>
			</div>
		</div>
	);
};

TrophyPage.propTypes = {
	trophies: PropTypes.arrayOf(Trophy.propTypes.trophy),
	saveTrophies: PropTypes.func
}

TrophyPage.defaultProps = {
	saveTrophies: function(trophies) {
	}
}

export default TrophyPage;