import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './login-style.css';

const PasswordChangedConfirmation = () => {
	return (
		<Router>
			<div className="login--form--container">
				<h2>Reset Password</h2>
				<div className="forgot-password--confirmation">
					<p>Your password was changed successfully.</p>
					<p>You will be redirected to the login page momentarily, or <Link to="/login">login now</Link>.</p>
				</div>
			</div>
		</Router>
	);
};

export default PasswordChangedConfirmation;