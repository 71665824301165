import React from 'react';
import PropTypes from 'prop-types';
import './general-style.css';

const General = ({ title, text, ...props }) => {

	return (
		<div className="general--container">
			<div className="general--title">
				{title}
			</div>
			<div className="general--text">
				{text}
			</div>
		</div>
	);
};

General.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string
}

export default General;