
const useRadioAnswer = (radioGroupName, value, submittedAnswer, isGateway = false) => {

	const answerId = `${radioGroupName}-${value}`;
	const hasSubmittedAnswer = (typeof submittedAnswer !== "undefined") && submittedAnswer !== null;
	const isAnswerEvaluated = hasSubmittedAnswer && submittedAnswer.evaluated;
	const isCorrectAnswerProvided = hasSubmittedAnswer && (typeof submittedAnswer.correct !== "undefined") && submittedAnswer.correct.length > 0;
	const isAnswerSelected = hasSubmittedAnswer && submittedAnswer.answer && submittedAnswer.answer.includes(value);
	const isAnswerCorrect = isCorrectAnswerProvided && submittedAnswer.correct.includes(value);

	// TODO: this was the way for SpinningWheel, but I wonder if that's wrong
	// const isChecked = isCorrectAnswerProvided ? isAnswerCorrect : false;
	const isChecked = isAnswerSelected;
	const isDisabled = isCorrectAnswerProvided ? true : isAnswerCorrect;

	// const isShowCorrectGlyph = isAnswerEvaluated && isCorrectAnswerProvided && isAnswerSelected && isAnswerCorrect;
	const isShowCorrectGlyph = (isAnswerEvaluated && isAnswerCorrect && !isGateway) || (isAnswerEvaluated && isCorrectAnswerProvided && isAnswerSelected && isAnswerCorrect && isGateway);
	const isShowIncorrectGlyph = (isAnswerEvaluated && isCorrectAnswerProvided && isAnswerSelected && !isAnswerCorrect) || (isAnswerEvaluated && !isCorrectAnswerProvided && isAnswerSelected);

	return {
		answerId,
		isAnswerEvaluated,
		isCorrectAnswerProvided,
		isAnswerSelected,
		isAnswerCorrect,
		isChecked,
		isDisabled,
		isShowCorrectGlyph,
		isShowIncorrectGlyph
	}
}

export default useRadioAnswer;