import React from 'react';
import useWaitTimer from '../hooks/useWaitTimer';
import ActivityButton from './ActivityButton';

const ActivityNav = ({ initialWaitTime, navHelper, ...props }) => {

	const waitTimer = useWaitTimer(initialWaitTime);

	const nextButtonClicked = () => {
		waitTimer.clearTimer();
		navHelper.handleNext();
	};

	const previousButtonClicked = () => {
		waitTimer.clearTimer();
		navHelper.handlePrevious();
	};

	const nextButtonText = (navHelper.isFinalActivity() ? "Finish" : "Next") +
		(waitTimer.isTimeRemaining() && waitTimer.formattedWaitTime ? ` in ${waitTimer.formattedWaitTime}` : "");

	return (
		<div className="topic--activity--footer dark">
			<div className="topic--activity--nav">
				<div className="topic--activity--nav--buttons">
					<div>
						{navHelper.hasPreviousView() &&
							<ActivityButton text="Previous" disabled={false} clickHandler={previousButtonClicked} />
						}
					</div>

					<ActivityButton text={nextButtonText} disabled={waitTimer.isTimeRemaining() || !navHelper.isNextButtonEnabled} clickHandler={nextButtonClicked} />
				</div>
			</div>
		</div>
	);
};

export default ActivityNav;