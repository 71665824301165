import React from 'react';
import PropTypes from 'prop-types';

const EnterTextQuestion = ({ text, image, imageAlt, ...props }) => {

	return (
		<div className="enter-text--question--container">
			<div id="enter-text-label" className="enter-text--question--text text-center">
				{text}
			</div>

			{image &&
				<div className="enter-text--image--container">
					<img
						className="enter-text--image"
						alt={imageAlt}
						src={
							process.env.REACT_APP_URL +
							'/assets/images/activities' +
							image
						}
					/>
				</div>
			}
		</div>
	);
};

EnterTextQuestion.propTypes = {
	text: PropTypes.string,
	image: PropTypes.string
}

export default EnterTextQuestion;