import React from 'react';
import PropTypes from 'prop-types';

const UserName = ( { username, ...props } ) => {
	return (
		<div className="profile--header--username dark" aria-label={`Your username is: ${username}`}>{username}</div>
	)
}

UserName.propTypes = {
	username: PropTypes.string
}

UserName.defaultProps = {
	username: "Boaty McBoatFace"
}

export default UserName