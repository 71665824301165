import React from 'react';
import PropTypes from 'prop-types';

export const Error = ({ error }) => {
	return (
		<div className="error--container">
			<p>There was an error loading the {error}.<br />
				Try reloading the page. If the problem presists, try logging out and logging back in to the app</p>
		</div>
	);
};

Error.propTypes = {
	error: PropTypes.string
};

Error.defaultProps = {
	error: "<unknown>"
};