import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './login-style.css';

const PasswordResetExpiration = () => {
	return (
		<Router>
			<div className="login--form--container">
				<h2>Reset Password</h2>
				<div className="forgot-password--confirmation">
					<p>The password reset link has expired. Please return to the <Link to="/password">Forgot Password page</Link> to generate a new link.</p>
				</div>
			</div>
		</Router>
	);
};

export default PasswordResetExpiration;