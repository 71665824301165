import React from 'react';
import { Table } from 'react-bootstrap';

/*
 * Required properties:
 * - actionsMenu: a function that returns JSX element defining an action menu for each row in the table
 */
const InstructorClassesTable = ({ voyClasses, actionsMenu, ...props }) => {

	const studentsPageLink = (voyClass) => {
		return <a href={`/instructor/dashboard/class/${voyClass.id}/students`}>{voyClass.name}</a>
	};

	const createClassRow = (voyClass) => {
		return (
			<tr key={`row-${voyClass.id}`}>
				<td>{studentsPageLink(voyClass)}</td>
				<td className="centered">{voyClass.totalStudents}</td>
				<td className="centered">{actionsMenu(voyClass)}</td>
			</tr>);
	};

	return (
		<Table responsive bordered>
			<colgroup>
				<col style={{ width: "80%", minWidth: "80%" }} />
				<col style={{ width: "10%", minWidth: "10%" }} />
				<col style={{ width: "10%", minWidth: "10%" }} />
			</colgroup>
			<thead>
				<tr>
					<th>Name</th>
					<th className="centered">Students</th>
					<th className="centered">Actions</th>
				</tr>
			</thead>
			<tbody>
				{voyClasses.map(function (voyClass) {
					return createClassRow(voyClass);
				})}
			</tbody>
		</Table>

	);
};

export default InstructorClassesTable;