import React, { useState } from 'react';
import { useDidMount, useDidUpdate } from 'rooks';
import { Loader } from '../common/Loader';
import { addUser, fetchClassByCode } from '../api/instructors';
import './instructor-style.css';
import '../Login/login-style.css';
import { useParams } from 'react-router';
import UserSetupForm from './UserSetupForm';
import { useHistory } from 'react-router';

const StudentSetupPage = ({ ...props }) => {

	const { classCode } = useParams();
	const [errorCount, setErrorCount] = useState(0);
	const [voyClass, setVoyClass] = useState({classId: null, className: '', locationUuid: '', locationName: ''});
	const [isFetching, setIsFetching] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRedirectToLogin, setIsRedirectToLogin] = useState(false);
	const [submitError, setSubmitError] = useState("");

	const browserHistory = useHistory();

	useDidMount(() => {
		sessionStorage.clear();
		fetchClassByCode(classCode).then(function (response) {
			switch (response.data.code) {
				case 200:
					setVoyClass(response.data.voyClass);
					setIsFetching(false);
					break;

				default:
					console.error("Could not fetch class");
			}
		});
	});

	useDidUpdate(() => {
		if (isRedirectToLogin) {
			setTimeout(function () {
				browserHistory.replace("/login");
			}, 5000);
		}
	}, [isRedirectToLogin]);

	if (errorCount > 0) {
		document.title = errorCount + " Errors - Add Student";
	} else {
		document.title = "Add Student";
	}

	const handleSubmit = (student) => {
		setIsSubmitting(true);
		addUser({ ...student, classCode: classCode, location: voyClass.locationUuid, "instructor": 0 }).then((response) => {
			switch (response.data.code) {
				case 200:
					setIsRedirectToLogin(true);
					break;
				case 1404:
					setSubmitError('The specified location was not found.');
					break;
				case 2404:
					setSubmitError('The specified name was not valid.');
					break;
				case 3404:
					setSubmitError('The specified email was not valid.');
					break;
				case 3405:
					setSubmitError('The specified emails did not match.');
					break;
				case 4404:
					setSubmitError('The specified password was not valid.');
					break;
				case 4405:
					setSubmitError('The specified passwords did not match.');
					break;
				case 3403:
					setSubmitError('The specified email address is already in use.');
					break;
				case 5000:
					setSubmitError('Unknown error occurred trying to add user.');
					break;
				case 5404:
					setSubmitError('The specified class was not found.');
					break;
				default:
					setSubmitError("Encountered an error trying to add the student");
					break;
			}
			setIsSubmitting(false);
		});
	}

	return (
		<div className="login--container">
			<div className="login--logo--container">
				<img
					className="login--logo"
					src={
						process.env.REACT_APP_URL +
						'/assets/images/app/logo-bg.png'
					}
					alt="Vision of You Logo"
					title="Welcome to Vision of You"
				/>
			</div>

			{isRedirectToLogin &&
				<div className="login--form--container instructor-add--form instructor-add--confirm">
					<h2>Add Student</h2>
					<div>The student account was created successfully.</div>
					<div>You will be redirected to the login page momentarily, or <a href="/login">login now</a>.</div>
				</div>
			}

			{!isRedirectToLogin &&
				<div className="user--add login--form--container instructor-add--form">
					<h2>Add Student</h2>
					{(isFetching || isSubmitting) &&
						<div className="loader--container">
							<Loader />
						</div>
					}

					{submitError &&
						<div className="error form-error top-level-error">{submitError}</div>
					}

					{!isFetching && voyClass &&
						<div>
							<span className="instructor-add--input-group mb-2">
								<label>Location</label>
								<span className="instructor-add--input">
									<span>{voyClass.locationName}</span>
								</span>
							</span>
							<span className="instructor-add--input-group mb-2">
								<label>Class</label>
								<span className="instructor-add--input">
									<span>{voyClass.className}</span>
								</span>
							</span>
							<UserSetupForm addUser={addUser} setErrorCount={setErrorCount} handleSubmit={handleSubmit} />
						</div>
					}
				</div>
			}
		</div>
	);
};

export default StudentSetupPage;