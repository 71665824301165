import React from 'react';
import PropTypes from 'prop-types';
import IdentityPlanet from './IdentityPlanet/IdentityPlanet';

const IdentityPlanetView = ({ view, ...props }) => {

	return (
		<IdentityPlanet 
			crust={view.identityPlanet.crust} 
			mantle={view.identityPlanet.mantle} 
			outerCore={view.identityPlanet.outerCore} 
			innerCore={view.identityPlanet.innerCore} 
			asteroids={view.identityPlanet.asteroids} />
	);
};

IdentityPlanetView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['identityPlanet']),
		identityPlanet: PropTypes.shape(IdentityPlanet.propTypes)
	}).isRequired
}

export default IdentityPlanetView;