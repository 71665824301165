import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './ProvideAuth';

const StudentOrInstructor = ({ ...props }) => {
	let auth = useAuth();
	return (
		<Route {...props} render={({ location }) => {
			return auth.user ?

			auth.user.isInstructor ? 

				<Redirect to={{
					pathname: '/instructor/dashboard/classes',
					state: { from: location }
				}} />

				:

				<Redirect to={{
					pathname: '/profile',
					state: { from: location }
				}} />

			:

			<Redirect to={{
					pathname: '/login',
					state: { from: location }
				}} />

		}} />
	);
};

export default StudentOrInstructor;
