import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { validate } from 'validate.js';
import constraints from './reset-password-constraints';
import './login-style.css';


const PasswordResetForm = ( { handleSubmit, email, ...props } ) => {

    const [submitError, setSubmitError] = useState('');

	return (
		<div className="login--form--container reset-password-form">
			<h2>Reset Password</h2>

			<Formik
				validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ password: '', passwordConfirm: '' }}
				validate={(values) => {
					const errors = {};
					let validationResult = validate(values, constraints)
					if (typeof validationResult !== 'undefined') {
						if (validationResult.password) {
							errors.password = validationResult.password[0];
						}
						if (validationResult.passwordConfirm) {
							errors.passwordConfirm = validationResult.passwordConfirm[0];
						}
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values).then(
						result => {
							
						},
						error => {
                            setSubmitting(false);
                            setSubmitError(error);
                        }
					);
            }}>

			{ formik =>
				<Form className="login--form side-by-side" autoComplete="off" noValidate>
					<span className="login--form--input-group">
                        <label>Email</label>
                        <span className="reset-password--input">
                            <span>{email}</span>
                        </span>
                    </span>

                    <span className="login--form--input-group">
                        <label htmlFor="reset-password--password">Password</label>
                        <div className={["reset-password--input", formik.errors.password && "error"].filter(e => !!e).join(' ')}>
                            <Field
                                id="reset-password--password"
                                name="password"
                                className="login--input"
                                type="password"
                                aria-describedby="password-error"
                                placeholder="Required"
                                autoComplete="off" 
                            />
                            <span id="password-error" className="form-error">
                                <ErrorMessage name="password" />
                            </span>
                        </div>
                    </span>

                    <span className="login--form--input-group">
                        <label htmlFor="reset-password--password-confirm">Confirm Password</label>
                        <div className={["reset-password--input", formik.errors.passwordConfirm && "error"].filter(e => !!e).join(' ')}>
                            <Field
                                id="reset-password--password-confirm"
                                name="passwordConfirm"
                                className="login--input"
                                type="password"
                                aria-describedby="password-confirm-error"
                                placeholder="Required"
                                autoComplete="off" 
                            />
                            <span id="password-confirm-error" className="form-error">
                                <ErrorMessage name="passwordConfirm" />
                            </span>
                        </div>
                    </span>
                    {submitError &&
                        <span className="login--form--input-group">
                            <label></label>
                            <div className="reset-password--input error">
                                <span className="form-error">{submitError}</span>
                            </div>
                        </span>
                    }
                    <span className="login--form--input-group">
                        <div id="password-policy">
                            Passwords must meet the following criteria:
                            <ul>
                            <li>Must be 12 characters or longer</li>
                            <li>Must include 1 or more lower case letters</li>
                            <li>Must include 1 or more upper case letters</li>
                            <li>Must include 1 or more digits</li>
                            <li>Cannot match your email address</li>
                            </ul>
                        </div>
                    </span>
                    <span id="password-policy-hidden" className="sr-only">Passwords must meet the following criteria. They must be 12 characters or longer, they must include 1 or more lower case letters,
                        they must include 1 or more upper case letters, they must include 1 or more digits, and they cannot match your email address.
                    </span>

                    <div className="login--button--container">
                        <Container>
                            <Row xs="1" sm="2">
                                <Col xs={{ order: 'last' }} className="px-0"><Button className="float-end" variant="primary" type="submit">Submit</Button></Col>
                                <Col xs={{ order: 'first' }} className="px-0"><Button className="float-start" variant="secondary" href="/login">Cancel</Button></Col>
                            </Row>
                        </Container>
                    </div>

				</Form>
			}

			</Formik>
		</div>
	);
};

PasswordResetForm.propTypes = {
	email: PropTypes.string,
	handleSubmit: PropTypes.func // returns a Promise
};

PasswordResetForm.defaultProps = {
	email: "david@42lines.net",
	handleSubmit: function (values) {
		return new Promise((resolve, reject) => {
			setTimeout(() => resolve("done!"), 1000);
		});
	}
};

export default PasswordResetForm;
