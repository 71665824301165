import React from 'react';
import PropTypes from 'prop-types';
import UnitCard from './UnitCard';

const UnitGrid = ({ units, ...props }) => {

	return (
		<div id="units-grid" className="profile--units-grid" role="region" aria-labelledby="unit-grid-heading">
			<h2 id="unit-grid-heading" className="sr-only">Course Units</h2>
			{units &&
				units.map((unit, index) =>
					<UnitCard key={'unit-card-' + index}
						locked={unit.locked} 
						icon={unit.icon} 
						title={unit.title} 
						number={unit.id} 
						topics={unit.topics} />
				)
			}
		</div>
	);
};

UnitGrid.propTypes = {
	units: PropTypes.array
}

UnitGrid.defaultProps = {
	units: []
}

export default UnitGrid;