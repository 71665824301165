import React from 'react';
import PropTypes from 'prop-types';
import OverallProgress from './OverallProgress';
import UserPoints from './UserPoints';
import UserName from './UserName';
import TrophyDisplay from './TrophyDisplay';
import ProfileModal from './ProfileModal';

const ProfileHeader = ({ profile, ...props }) => {

	return (
		<div id="profile-header" className="profile--header" role="region" aria-labelledby="profile-header-heading">
			<h2 id="profile-header-heading" className="sr-only">Profile Header</h2>
			<div className="profile--header--upper">
				<UserName username={profile.username} />
				<TrophyDisplay trophies={profile.trophiesFull} />
			</div>
			<div className="profile--header--middle">
				<UserPoints totalPoints={profile.totalPoints} awardedPoints={profile.rewards.points} />
				<div className="profile--modals--container" role="region" aria-label="Reference Sheets">
					{profile.modals &&
						profile.modals.map((modal, index) =>
							<ProfileModal
								key={index}
								id={modal.id}
								name={modal.name}
								icon={modal.icon}
								image={modal.image}
								link={modal.link}
								popovers={modal.popovers}
							/>
						)
					}
				</div>
			</div>
			<div className="profile--header--lower">
				{profile.totalProgress > 0 &&
					<OverallProgress progress={profile.totalProgress} />
				}
			</div>
		</div>
	);
};

ProfileHeader.propTypes = {
	profile: PropTypes.shape({
		username: PropTypes.string,
		displayName: PropTypes.string,
		trophies: PropTypes.arrayOf(PropTypes.string),
		totalPoints: PropTypes.number,
		totalProgress: PropTypes.number,
		modals: PropTypes.arrayOf(PropTypes.shape(ProfileModal.propTypes)),
		rewards: PropTypes.shape({
			points: PropTypes.number,
			trophies: PropTypes.array
		})
	})
}

export default ProfileHeader;