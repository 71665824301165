import API from './api';
import { stringify } from 'qs';

export const fetchActivity = (activityId, authToken) => {
	return API.post("/route", stringify({authToken: authToken, page: "activity", activityId: activityId}));
};

export const submitAnswer = (authToken, activityId, answer) => {
	return API.post("/submit", stringify({
		authToken: authToken,
		activityId: activityId,
		answers: answer
	}));
};

export const welcomeVideoSeen = (authToken) => {
	return API.post("/welcomeVideoSeen", stringify({authToken: authToken}));
};