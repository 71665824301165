import React from 'react';
import PropTypes from 'prop-types';

const LabellingDropdown = ({ questionNumber, answers, selectAriaLabel, ...props }) => {

	const buildOptions = () => {
		return answers.map((answer, index) => {
			return <option
				key={questionNumber + '-' + index}
				className="labelling--option"
				value={index}
				disabled={index === 0 ? true : false}
			>
				{answer}
			</option>
		});
	}

	return (
		<div className="labelling--answers">
			<div className="labelling--glyph--selection--container">
				<select
					aria-label={selectAriaLabel}
					className="labelling--selection"
					name={questionNumber}>

					{buildOptions()}

				</select>
			</div>
		</div>
	);
};

LabellingDropdown.propTypes = {
	selectAriaLabel: PropTypes.string,
	questionNumber: PropTypes.number,
	answers: PropTypes.arrayOf(PropTypes.string)
}

export default LabellingDropdown;