import React from 'react';
import PropTypes from 'prop-types';
import SpinningWheelDisplay from './SpinningWheel/SpinningWheelDisplay';
import SpinningWheelQuestion from './SpinningWheel/SpinningWheelQuestion';

const SpinningWheelView = ({ view, submittedAnswers, activityHelper, ...props }) => {
	const question = view.questions[0];
	const submittedAnswer = submittedAnswers ? submittedAnswers[question.questionNumber] : null;

	const isQuestion = () => {
		return view.answers && view.answers.length > 0;
	}

	if (isQuestion()) {
		if (view.questions.length > 1) {
			alert('Unexpected multiple questions. Please report this activity to David.');
		}

		return (
			<SpinningWheelQuestion
				inputType={view.inputType}
				prompt={view.prompt}
				foreground={view.foreground}
				background={view.background}
				rotations={view.rotations}
				question={question}
				questionNumber="0"
				answers={view.answers}
				isGateway={activityHelper.isGateway}
				handleSubmit={activityHelper.handleSubmit}
				submittedAnswer={submittedAnswer}
				buttonHelper={{ enableNextButton: activityHelper.enableNextButton, disableNextButton: activityHelper.disableNextButton }}
			/>
		);
	}

	if (!isQuestion()) {
		return (
			<SpinningWheelDisplay
				inputType={view.inputType}
				prompt={view.prompt}
				foreground={view.foreground}
				background={view.background}
				rotations={view.rotations}
				questions={view.questions} />
		);
	}
};

SpinningWheelView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['spinningWheel']).isRequired,
		inputType: PropTypes.oneOf(['checkbox', 'radio']),
		prompt: PropTypes.string.isRequired,
		background: PropTypes.string.isRequired,
		foreground: PropTypes.string.isRequired,
		rotations: PropTypes.arrayOf(PropTypes.number).isRequired,
		questions: PropTypes.arrayOf(PropTypes.shape({
			term: PropTypes.string,
			definition: PropTypes.string
		})).isRequired,
		answers: PropTypes.arrayOf(PropTypes.shape({
			text: PropTypes.string
		}))
	}).isRequired
}

export default SpinningWheelView;