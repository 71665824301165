import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PasswordResetForm from './PasswordResetForm';
import PasswordResetExpiration from './PasswordResetExpiration';
import PasswordChangedConfirmation from './PasswordChangedConfirmation';
import ForgotPasswordForm from './ForgotPasswordForm';
import PasswordResetError from './PasswordResetError';
import { Loader } from '../common/Loader';
import * as auth from '../api/login.js';
import { useEffect } from 'react';
import useTitle from '../hooks/useTitle';

// class PasswordManagementPage extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			loading: false,
// 			email: '',
// 			passwordChanged: false,

// 		};
// 	}
// }
const PasswordManagementPage = () => {
	const [loading, setLoading] = useState(true);
	const [email, setEmail] = useState('');
	const [studentId, setStudentId] = useState('');
	const [passwordChanged, setPasswordChanged] = useState(false);
	const [passwordChangeError, setPasswordChangeError] = useState(false);
	const { refId } = useParams();
	const history = useHistory();

	useTitle('Vision of You Password Management');

	useEffect(() => {
		if (refId && !email) {
			auth.fetchPasswordResetRequest(refId)
				.then((response) => {
					if (response.data.code === 200) {
						let results = response.data.results;
						if (results.email) {
							setEmail(results.email);
						}
						if (results.id) {
							setStudentId(results.id);
						}
					} else {
						console.error("Did not get expected code: " + response.code);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else if (!refId) {
			setLoading(false);
		}
	}, [loading, email, refId]);

	useEffect(() => {
		if (passwordChanged) {
			setTimeout(() => history.replace('/login'), 5000);
		}
	}, [passwordChanged, history]);

	const changePassword = (values) => {
		setLoading(true);
		return auth.changePassword(refId, studentId, values.password, values.passwordConfirm)
			.then((response) => {
				if (response.data.code === 200) {
					setPasswordChanged(true);
				} else {
					setPasswordChangeError(true);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="login--container">
			<div className="login--logo--container">
				<img
					className="login--logo"
					src={process.env.REACT_APP_URL + '/assets/images/app/logo-bg.png'}
					alt="Vision of You"
				/>
			</div>

			{loading &&
				<Loader />
			}

			{!passwordChangeError && !passwordChanged && !loading && !refId &&
				<ForgotPasswordForm handleSubmit={auth.forgotPassword} />
			}

			{!passwordChangeError && !passwordChanged && !email && !loading && refId &&
				<PasswordResetExpiration />
			}

			{!passwordChangeError && !passwordChanged && email && !loading &&
				<PasswordResetForm handleSubmit={changePassword} email={email} />
			}

			{passwordChanged && !loading &&
				<PasswordChangedConfirmation />
			}

			{passwordChangeError && !loading &&
				<PasswordResetError />
			}
		</div>
	);
};

export default PasswordManagementPage;