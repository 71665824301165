import API from './api';
import { stringify } from 'qs';

export const fetchLocation = (locationCode) => {
	return API.post("/location", stringify({uuid: locationCode}));
}

export const addUser = (user) => {
	return API.post("/user/add", stringify(user));
}

export const fetchInstructorClasses = (authToken) => {
	return API.post("/instructor/classes", stringify({authToken: authToken}));
}

export const saveInstructorClass = (authToken, voyClass) => {
	return API.post("/instructor/classes/save", stringify({authToken: authToken, classId: voyClass.id, className: voyClass.name, active: voyClass.active}));
}

export const fetchInstructorClass = (authToken, classId) => {
	return API.post("/instructor/classes/single", stringify({authToken: authToken, classId: classId}));
}

export const fetchClassByCode = (classCode) => {
	return API.post("/instructor/classes/single/byCode", stringify({classCode: classCode}));
}

export const toggleStudentStatus = (authToken, student, classId) => {
	return API.post("/student/toggleStatus", stringify({authToken: authToken, student: student, classId: classId}));
}

export const updateStudent = (authToken, studentId, studentName, studentEmail) => {
	return API.post("/student/update", stringify({authToken: authToken, student: studentId, name: studentName, email: studentEmail }));
}

export const updateStudentPassword = (authToken, studentId, password, passwordConfirm) => {
	return API.post("/student/update", stringify({authToken: authToken, student: studentId, password: password, passwordConfirm: passwordConfirm }));
}

export const fetchStudentProgress = (authToken, studentId, classId) => {
	return API.post("/student/progress", stringify({authToken: authToken, student: studentId, classId: classId }));
}