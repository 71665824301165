import React from 'react';
import { Button } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useDidUpdate, useDidMount } from 'rooks';
import QuizQuestion from './QuizQuestion';
import { hasSubmittedAnswers } from './utils';

const RadioChoiceQuiz = ({ questions, answers, storedAnswers, submittedAnswers, activityHelper, isActivityDirty, ...props }) => {
	const isAtEndOfForm = (!activityHelper.requiresMultiPageForm() || (activityHelper.requiresMultiPageForm() && activityHelper.isAtLastView()));

	const handleChange = (event) => {
		activityHelper.markActivityDirty();
	};

	const checkNextButton = () => {
		if (hasSubmittedAnswers(submittedAnswers) && !isActivityDirty) {
			activityHelper.enableNextButton();
		} else {
			activityHelper.disableNextButton();
		}
	};

	const hasSubmittedAnswer = (question) => {
		return submittedAnswers !== null && typeof submittedAnswers !== 'undefined' && submittedAnswers.length > 0 && submittedAnswers[question.questionNumber];
	};

	const getSubmittedAnswer = (question) => {
		return submittedAnswers[question.questionNumber].answer[0].toString();
	};

	const hasStoredAnswer = (question) => {
		return storedAnswers !== null && typeof storedAnswers !== 'undefined' && storedAnswers.length > 0 && storedAnswers[question.questionNumber];
	};

	const getStoredAnswer = (question) => {
		return storedAnswers[question.questionNumber].answer[0].toString();
	};

	const buildInitialValues = () => {
		let answers = {};
		questions.forEach((question) => {
			let answer = '';
			if (isActivityDirty && hasStoredAnswer(question)) {
				answer = getStoredAnswer(question);
			} else if (hasSubmittedAnswer(question)) {
				answer = getSubmittedAnswer(question);
			} else if (hasStoredAnswer(question)) {
				answer = getStoredAnswer(question);
			}

			answers[question.questionNumber.toString()] = answer;
		});

		return answers;
	};

	useDidMount(() => {
		checkNextButton();
	});

	useDidUpdate(() => {
		checkNextButton();
	}, [isActivityDirty, submittedAnswers]);

	return (
		<Formik
			initialValues={buildInitialValues()}
			validate={(values) => {
				let errors = {};
				let answers = {};
				questions.forEach((question) => {
					let number = question.questionNumber.toString();
					if (values[number]) {
						answers[number] = values[number];
					} else {
						errors[number] = "Please select an answer and try again.";
						answers[number] = '';
					}
				});
				return errors;
			}}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={(values) => {
				if (isAtEndOfForm) {
					activityHelper.handleSubmit(values);
				} else {
					activityHelper.handleSubmit(values, false)
						.then(() => activityHelper.handleNext());
				}
			}}
		>
			{({ errors }) => (
				<Form>
					{questions.map((question) =>
						<QuizQuestion key={`question-${question.questionNumber}`} question={question} answers={answers} handleChange={handleChange} submittedAnswer={hasSubmittedAnswers(submittedAnswers) ? submittedAnswers[question.questionNumber] : null} errors={errors} isGateway={activityHelper.isGateway} />
					)}

					<Button className="mt-2" variant="light" type="submit" disabled={submittedAnswers && !isActivityDirty}>{isAtEndOfForm ? "Submit" : "Continue"}</Button>
				</Form>
			)}
		</Formik>
	);
};

export default RadioChoiceQuiz;