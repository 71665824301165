import { validate } from 'validate.js';

validate.validators.passwordCriteria = function (value, options, key, attributes) {
    let errors = [];
    if (typeof value === 'undefined') {
        return errors;
    }
    
    if (value.length < 12) {
        errors.push("^The password must be 12 characters or longer.");
    }

    if (!/[a-z]+/.test(value)) {
        errors.push("^The password must include 1 or more lower case letters.");
    }

    if (!/[A-Z]+/.test(value)) {
        errors.push("^The password must include 1 or more upper case letters.");
    }

    if (!/[0-9]+/.test(value)) {
        errors.push("^The password must include 1 or more digits.");
    }

    if (value === attributes['email']) {
        errors.push("^The password cannot match your email address.");
    }
    
    return errors;
};
