import React from 'react';
import PropTypes from 'prop-types';
import Quiz from './Quiz/Quiz';

const QuizView = ({ view, storedAnswers, submittedAnswers, activityHelper, isActivityDirty, ...props }) => {

	if (view.questions.length > 1 && view.inputType === 'checkbox') {
		alert('Unexpected multiple questions for checkbox type. Please report this activity to David.');
	}

	return (
		<Quiz prompt={view.prompt} questions={view.questions} answers={view.answers} inputType={view.inputType} condition={view.condition} min={view.min} limit={view.limit} storedAnswers={storedAnswers} submittedAnswers={submittedAnswers} isActivityDirty={isActivityDirty} activityHelper={activityHelper} />
	);

};

QuizView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['quiz']),
		...Quiz.propTypes
	}).isRequired
}

export default QuizView;