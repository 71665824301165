import React from 'react';
import PropTypes from 'prop-types';

const SelectionModalBody = ({ image, name, bio, goals, ...props }) => {

	return (
		<div className="interactive-graphic--modal--selection--container">
			<img className="interactive-graphic--modal--character--image"
				src={process.env.REACT_APP_URL + '/assets/images/characters' + image}
				alt={name} />

			<div className="interactive-graphic--modal--character--info">
				<div className="interactive-graphic--modal--character--name">{name}</div>
				<div className="interactive-graphic--modal--character--bio">{bio}</div>
				<div className="interactive-graphic--modal--character--goals">
					Goals:
					<ul className="interactive-graphic--modal--character--goals--list">
						{goals &&
							goals.map((goal, index) => <li key={index} className="interactive-graphic--modal--character--goal">{goal}</li>)
						}
					</ul>
				</div>
			</div>
		</div>
	);
};

SelectionModalBody.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
	bio: PropTypes.string,
	goals: PropTypes.arrayOf(PropTypes.string)
}

export default SelectionModalBody;