import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IdentityPlanetQuestion from './IdentityPlanetQuestion';
import './identity-planet-style.css';

const IdentityPlanet = ({ crust, mantle, outerCore, innerCore, asteroids, ...props }) => {

	const crustOverlay = (
		<Popover id="popover-crust" title="Identity Planet Crust">
			{
				crust.map((question, index) => <IdentityPlanetQuestion key={index} text={question.text} answers={question.answers} />)
			}
		</Popover>
	);

	const mantleOverlay = (
		<Popover id="popover-mantle" title="Identity Planet Mantle">
			{
				mantle.map((question, index) => <IdentityPlanetQuestion key={index} text={question.text} answers={question.answers} />)
			}
		</Popover>
	);

	const outerCoreOverlay = (
		<Popover id="popover-outer-core" title="Identity Planet Outer Core">
			{
				outerCore.map((question, index) => <IdentityPlanetQuestion key={index} text={question.text} answers={question.answers} />)
			}
		</Popover>
	);

	const innerCoreOverlay = (
		<Popover id="popover-inner-core" title="Identity Planet Inner Core">
			{
				innerCore.map((question, index) => <IdentityPlanetQuestion key={index} text={question.text} answers={question.answers} />)
			}
		</Popover>
	);

	const asteroidsOverlay = (
		<Popover id="popover-asteroids" title="Identity Planet Asteroids">
			{
				asteroids.map((question, index) => <IdentityPlanetQuestion key={index} text={question.text} answers={question.answers} />)
			}
		</Popover>
	);

	return (
		<div className="identity-planet--image--container">
			<img
				className="identity-planet--image"
				src={process.env.REACT_APP_URL + '/assets/images/app/identity-planet.svg'}
				alt="Identity Planet. Hover over the labels to see your responses match up with the different layers that make you, you!"
			/>

			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={crustOverlay}>
				<div id="identity-planet--crust" className="identity-planet--hover-area" tabIndex={0} aria-label="Identity Planet Crust" />
			</OverlayTrigger>

			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={mantleOverlay} >
				<div id="identity-planet--mantle" className="identity-planet--hover-area" tabIndex={0} aria-label="Identity Planet Mantle" />
			</OverlayTrigger>

			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={outerCoreOverlay} >
				<div id="identity-planet--outer-core" className="identity-planet--hover-area" tabIndex={0} aria-label="Identity Planet Outer Core" />
			</OverlayTrigger>

			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={innerCoreOverlay} >
				<div id="identity-planet--inner-core" className="identity-planet--hover-area" tabIndex={0} aria-label="Identity Planet Inner Core" />
			</OverlayTrigger>

			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={asteroidsOverlay} >
				<div id="identity-planet--asteroids" className="identity-planet--hover-area" tabIndex={0} aria-label="Identity Planet Asteroids" />
			</OverlayTrigger>
		</div>
	);
};

IdentityPlanet.propTypes = {
	crust: PropTypes.arrayOf(PropTypes.shape(IdentityPlanetQuestion.propTypes)),
	mantle: PropTypes.arrayOf(PropTypes.shape(IdentityPlanetQuestion.propTypes)),
	outerCore: PropTypes.arrayOf(PropTypes.shape(IdentityPlanetQuestion.propTypes)),
	innerCore: PropTypes.arrayOf(PropTypes.shape(IdentityPlanetQuestion.propTypes)),
	asteroids: PropTypes.arrayOf(PropTypes.shape(IdentityPlanetQuestion.propTypes))
}

IdentityPlanet.defaultProps = {
	crust: [],
	mantle: [],
	outerCore: [],
	innerCore: [],
	asteroids: []
}

export default IdentityPlanet;