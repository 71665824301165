import React from 'react';
import PropTypes from 'prop-types';
import InteractiveModel from './InteractiveModel/InteractiveModel';

const InteractiveModelView = ({ view, ...props }) => {

	return (
		<InteractiveModel prompt={view.prompt} title={view.title} src={view.src} placeholder={view.placeholder} supplemental={view.supplemental} />
	);
};

InteractiveModelView.propTypes = {
	view: PropTypes.shape({
		type: PropTypes.oneOf(['interactiveModel']),
		...InteractiveModel.propTypes
	})
}

export default InteractiveModelView;