import React from 'react';
import PropTypes from 'prop-types';

const SortingAnswer = ({ text, ...props }) => {

	return (
		<div className="sorting--category--header">{text}</div>
	);
};

SortingAnswer.propTypes = {
	text: PropTypes.string
}

export default SortingAnswer;