import React from 'react';
import './General/general-style.css';
import GatewayQuizSummary from './GatewayQuiz/GatewayQuizSummary';

const GatewayQuizSummaryView = ({ view, ...props }) => {
	return (
		<div className="general--container">
			<div className="general--title">
				{view.title}
			</div>
			<div className="general--text">
				{view.text}
			</div>
			
			<GatewayQuizSummary questions={view.questions} />
		</div>
	);
};

export default GatewayQuizSummaryView;