export const constraints = {
    password: {
        presence: {
            allowEmpty: false,
            message: "^The Password field is empty; it is a required field and must be filled in."
        },
        equality: {
            attribute: "username",
            message: "^The Password may not match the username.",
            comparator: function(v1, v2) {
                return JSON.stringify(v1) !== JSON.stringify(v2);
            }
        },
        passwordCriteria: true
    },
    passwordConfirm: {
        presence: {
            allowEmpty: false,
            message: "^The Confirm Password field is empty; it is a required field and must be filled in."
        },
        equality: {
            attribute: "password",
            message: "^The Confirm Password field does not match the Password field."
        }
    }
}

export default constraints;